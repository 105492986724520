<template>
    <div class="content" v-if="show">
        <SswgModal :visible.sync="modalVisibleStat" modalTitle="航程统计">
            <div class="v1">
                <div class="v101">
                    <el-select v-model="currShipId" filterable placeholder="请选择船名" style="width: 160px;">
                        <el-option v-for="item in shipList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                    </el-select>
                    <span style="margin-left: 10px;">航行类型：</span>
                    <el-select v-model="statType.value" placeholder="请选择" style="width: 100px; margin-right: 10px;">
                        <el-option v-for="item in statType.list" :key="item.value" :label="item.label" :value="item.value"></el-option>
                    </el-select>
                    <el-radio-group v-model="radio" @input="radioChange" style="display: none;">
                        <el-radio :label="4">按年</el-radio>
                        <el-radio :label="3">按月</el-radio>
                        <el-radio :label="1">按日</el-radio>
                        <el-radio :label="2">时间范围</el-radio>
                    </el-radio-group>
                    <span style="margin-left: 10px;">时间范围：</span>
                    <el-date-picker v-model="value2" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" style="width: 260px;">
                    </el-date-picker>
                </div>
                <div class="v102">
                    <span class="btn" @click="queryStat">查询统计</span>
                    <span class="btn" @click="exportExcel">导出Excel</span>
                </div>
            </div>
            <div class="v2">
                <span class="btn btn2" @click="add">添加</span>
            </div>
            <div class="v3">
                <span>航程次数：{{ voyageNum }}</span>
                <span>移泊次数：{{ shiftNum }}</span>
                <span>总航程(nm)：{{ totalVoyage }}</span>
                <span>总移泊里程(nm)：{{ totalShiftVoyage }}</span>
            </div>
            <div class="v4">
                <el-table ref="elTable" :data="dataList" stripe style="width: 100%;" highlight-current-row class="custom-table">
                    <el-table-column type="index" label="序号" width="50" align="center"/>
                    <el-table-column label="航程" width="180" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.startPortName+'---'+scope.row.endPortName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="航行类型" width="80" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.sailingType==1">航行</span>
                            <span v-else-if="scope.row.sailingType==2">移泊</span>
                            <span v-else>未知</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="mileage" label="里程数(nm)" width="80" align="center"/>
                    <el-table-column prop="numOfPassenger" label="旅客总数" width="80" align="center"/>
                    <el-table-column prop="sailTime" label="航行时间" width="100" align="center"/>
                    <el-table-column prop="avgSpeed" label="平均速度" width="80" align="center"/>
                    <el-table-column prop="beginTime" label="起始时间" width="140" align="center"/>
                    <el-table-column prop="endTime" label="终止时间" width="140" align="center"/>
                    <el-table-column prop="isForecast" label="是否预估" width="80" align="center">
                        <template slot-scope="scope">
                            <span v-if="scope.row.isForecast==1">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="110" align="center">
                        <template slot-scope="scope">
                            <span class="btn3" @click="edit(scope.row)">编辑</span>
                            <span class="btn3" @click="del(scope.row.id)">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </SswgModal>

        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" :openFooter="openFooter" @sure="sureModal">
            <div class="c1" v-if="currOperate=='add' || currOperate=='edit'">
                <span>始发港</span>
                <el-select v-model="portFrom.value" filterable placeholder="请选择" style="width: 170px;">
                    <el-option v-for="item in portFrom.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <span style="margin-left: 20px;">里程数(nm)</span>
                <el-input v-model="dg.mileage" type="number" clearable maxlength="20" style="width: 170px;"></el-input>
                <span>目的港</span>
                <el-select v-model="portTo.value" filterable placeholder="请选择" style="width: 170px;">
                    <el-option v-for="item in portTo.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <span style="margin-left: 20px;">航行类型</span>
                <el-select v-model="voyageType.value" placeholder="请选择" style="width: 170px;">
                    <el-option v-for="item in voyageType.list" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
                <span>开始时间</span>
                <el-date-picker v-model="dg.beginDateTime" type="datetime" placeholder="开始时间" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" style="width: 440px;"></el-date-picker>
                <span>终止时间</span>
                <el-date-picker v-model="dg.endDateTime" type="datetime" placeholder="终止时间" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" style="width: 440px;"></el-date-picker>
            </div>
            <div class="box-content" v-else-if="currOperate=='del'">
                {{ modalContent }}
            </div>
        </SswgModal>
    </div>
</template>

<script>

// import { utils, write } from 'xlsx';
import { saveAs } from 'file-saver';

// import ExcelJS from 'exceljs'

export default {
    // 父级提供的
    inject: ['app'],
    data() {
        return {
            show: false,
            dataList: [],

            shipList: [],
            currShipId: '',

            voyageNum: '--',
            shiftNum: '--',
            totalVoyage: '--',
            totalShiftVoyage: '--',

            modalVisibleStat: false,

            modalVisible: false,
            modalTitle: '提示',
            openFooter: true,
            modalContent: '',

            currOperate: '', // 当前操作 add edit del

            statType: {
                list: [
                    { value: '', label: '全部' },
                    { value: '1', label: '航行' },
                    { value: '2', label: '移泊' }
                ],
                value: ''
            },
            radio: 2,
            value2: [new Date(), new Date()],

            // 按日统计
            beginDate: this.parseTime(new Date()),

            // 始发港
            portFrom: {
                list: [],
                value: ''
            },

            // 目的港
            portTo: {
                list: [],
                value: ''
            },

            // 航行状态
            voyageType: {
                list: [
                    { value: 1, label: '航行' },
                    { value: 2, label: '移泊' }
                ],
                value: ''
            },

            dg: {
                currVoyageId: '',               // 当前编辑或删除的航班id
                mileage: '',                    // 里程数
                beginDateTime: undefined,
                endDateTime: undefined
            }


        }
    },
    methods: {

        radioChange(v){
            console.log(v)
            console.log(this.radio)
        },

        handleWeekChange(value){
            console.log(value)
            // if (value) {
            //     const [start, end] = value;
            //     console.log('周起始日期:', start);
            //     console.log('周结束日期:', end);
            // }
        },

        // 查询统计
        queryStat(){
            if(!this.currShipId){
                this.$message.warning('请选择船名')
                return
            }
            this.queryDataList()
        },

        convertSailingTypeName(type){
            let str = '--'
            if(type==1){
                str = '航行'
            }else if(type==2){
                str = '移泊'
            }
            return str
        },

        convertDataList(){
            let arr = []
            for(let i=0; i<this.dataList.length; i++){
                let item = this.dataList[i]
                let a = {
                    no: i+1,
                    name: item.startPortName+'---'+item.endPortName,
                    sailingType: this.convertSailingTypeName(item.sailingType),
                    mileage: item.mileage,
                    numOfPassenger: item.numOfPassenger,
                    sailTime: item.sailTime,
                    avgSpeed: item.avgSpeed,
                    beginTime: item.beginTime,
                    endTime: item.endTime,
                    isForecast: item.isForecast==1?'是':'否'
                }
                arr.push(a)
            }
            return arr
        },

        exportExcel(){
            const Exceljs = require('exceljs'); // 引入exceljs
	        const workbook = new Exceljs.Workbook(); // 创建工作簿
            const worksheet = workbook.addWorksheet('航程数据统计')

            const columns = [
                { header: '序号', key: 'no', width: 8},
                { header: '航程', key: 'name', width: 20},
                { header: '航行类型', key: 'sailingType', width: 10},
                { header: '里程数(nm)', key: 'mileage', width: 12},
                { header: '旅客总数', key: 'numOfPassenger', width: 10},
                { header: '航行时间', key: 'sailTime', width: 14},
                { header: '平均速度', key: 'avgSpeed', width: 10},
                { header: '起始时间', key: 'beginTime', width: 20},
                { header: '终止时间', key: 'endTime', width: 20},
                { header: '是否预估', key: 'isForecast', width: 10},
            ]
            worksheet.columns = columns

            worksheet.addRows(this.convertDataList())

            // 定义边框样式
            const borderStyle = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            worksheet.eachRow((row, rowIndex) => {
                if(rowIndex===1){
                    row.eachCell((cell, colIndex) => {
                        cell.font = { bold: true };
                        cell.alignment = {
                            vertical: 'middle', // 垂直居中
                            horizontal: 'center', // 水平居中
                            wrapText: true // 自动换行
                        };
                        cell.border = borderStyle
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '58c2de' } // 设置背景色
                        };
                    });
                }else{
                    // 循环每个单元格		
                    row.eachCell((cell, colIndex) => {
                        cell.alignment = {
                            vertical: 'middle', // 垂直居中
                            horizontal: 'center', // 水平居中
                            wrapText: true // 自动换行
                        };
                        cell.border = borderStyle
                    });
                }
            });

            // 下载工作簿
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), '航程数据统计.xlsx');
            });
        },

        // 设置显示、隐藏
        setShow(show){
            this.show = show
            this.modalVisibleStat = show
            this.shipList = this.app.shipList
            if(this.shipList.length>0){
                this.currShipId = this.shipList[0].id
            }
            // 重置
            this.dataList = []
            // 查询港口列表
            this.queryPortList()
        },

        // 查询港口列表
        queryPortList(){
            this.sswgPost('/port/fetchPortList', {}).then(res => {
                if(res){
                    this.portFrom.list = res.data
                    this.portTo.list = res.data
                }
            })
        },

        // 查询数据列表
        queryDataList(){
            let p = {
                shipInfoId: this.currShipId,
                sailingType: this.statType.value,
                type: this.radio,
                beginTime: this.beginDate,
            }

            if(this.radio==2){
                p.beginTime = this.parseTime(this.value2[0])
                p.endTime = this.parseTime(this.value2[1])
            }

            this.sswgPost('/voyage/voyageStatistics/list', p, true, 'json').then(res => {
                if(res){
                    console.log(res)
                    this.dataList = res.data.voyageStatisticsList
                    // 填充统计信息
                    this.voyageNum = res.data.voyageNum
                    this.shiftNum = res.data.shiftNum
                    this.totalVoyage = res.data.totalVoyage
                    this.totalShiftVoyage = res.data.totalShiftVoyage
                }
            })
        },

        add(){
            if(!this.currShipId){
                this.$message.warning('请选择船名')
                return
            }
            // 根据id找船名
            let ship = this.shipList.find(item => item.id === this.currShipId)
            this.currOperate = 'add'
            this.modalTitle = '添加航程  【'+ship.name+'】'
            this.modalVisible = true
        },

        edit(item){
            console.log(item)
            this.currOperate = 'edit'
            this.modalTitle = '编辑'
            this.modalVisible = true

            this.dg.currVoyageId = item.id
            this.portFrom.value = parseInt(item.startPortId)
            this.portTo.value = parseInt(item.endPortId)
            this.voyageType.value = item.sailingType
            this.dg.mileage = item.mileage
            this.dg.beginDateTime = item.beginTime
            this.dg.endDateTime = item.endTime
        },
        
        del(id){
            this.dg.currVoyageId = id
            this.currOperate = 'del'
            this.modalTitle = '提示'
            this.modalVisible = true
            this.modalContent = '确认删除此航程数据吗？'
        },

        // 确认modal
        sureModal(){
            if(this.currOperate=='add' || this.currOperate=='edit'){
                if(!this.portFrom.value){
                    this.$message.warning('请选择始发港')
                }else if(!this.portTo.value){
                    this.$message.warning('请选择目的港')
                }else if(!this.dg.mileage){
                    this.$message.warning('请填写里程数')
                }else if(!this.voyageType.value){
                    this.$message.warning('请选择航行类型')
                }else if(!this.dg.beginDateTime){
                    this.$message.warning('请选择开始时间')
                }else if(!this.dg.endDateTime){
                    this.$message.warning('请选择终止时间')
                }else {
                    let starPort = this.portFrom.list.find(item => item.id === this.portFrom.value)
                    let endPort = this.portTo.list.find(item => item.id === this.portTo.value)
                    let p = {
                        startPortId: this.portFrom.value,
                        startPortName: starPort.name,
                        endPortId: this.portTo.value,
                        endPortName: endPort.name,
                        mileage: this.dg.mileage,
                        sailingType: this.voyageType.value,
                        shipInfoId: this.currShipId,
                        beginTime: this.dg.beginDateTime,
                        endTime: this.dg.endDateTime
                    }
                    // 如果是编辑，则附加id
                    if(this.currOperate=='edit'){
                        p.id = this.dg.currVoyageId
                    }
                    this.sswgPost('/voyage/voyageStatistics/saveOrUpdate', p, true, 'json').then(res => {
                        if(res && res.code==200){
                            this.$message.success('操作成功')
                            this.closeModal()
                            this.queryStat()
                        }
                    })
                }
            }else if(this.currOperate=='del'){
                this.sswgGet('/voyage/voyageStatistics/remove/'+this.dg.currVoyageId, {}, true, 'json').then(res => {
                    if(res && res.code==200){
                        this.$message.success('操作成功')
                        this.closeModal()
                        this.queryStat()
                    }
                })
            }
        },

        closeModal(){
            this.modalVisible = false
        }

        
    }

}

</script>

<style lang="scss" scoped>
    .content {
        .v1 {
            // width: 1200px;
            // height: 500px;
            display: flex;
            // background-color: aliceblue;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 12px 10px;
        }
        .v2 {
            padding: 4px 10px;
            border-top: 1px dashed rgb(148, 147, 147);
            border-bottom: 1px dashed rgb(148, 147, 147);
        }
        .v3 {
            padding: 4px 10px;
            span {
                margin-right: 15px;
                font-size: 12px;
            }
        }
        .v4 {
            height: 440px;
            background-color: #FFFFFF;
            margin: 0 2px 2px 2px;
            overflow-y: auto;
        }

        .c1 {
            padding: 10px;
            width: 540px;
            span {
                display: inline-block;
                width: 80px;
                // margin-bottom: 20px;
                line-height: 40px;
                font-size: 14px;
            }
        }
    }

    .btn {
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        padding: 4px 10px;
        background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
        margin-left: 20px;
        color: #000;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
    }
    .btn2 {
        padding: 2px 20px;
        margin-left: 0px;
        margin-right: 20px;
    }

    .btn3 {
        color: #1762c3;
        border: 1px solid #0078d7;
        border-radius: 2px;
        padding: 1px 4px;
        background-color: #f0f0f0;
        cursor: pointer;
        font-size: 12px;
        margin-left: 4px;
        user-select: none;
    }

</style>

<style scoped>
    .content >>> .el-form-item {
        margin-bottom: 0px;
    }
    .content >>> .el-form-item__label {
        color: #FFFFFF;
    }
    .content >>> input.el-input__inner {
        font-size: 15px;
        font-weight: bold;
        height: 26px;
        line-height: 26px;
        background: none;
        color: #FFFFFF;
    }
    .content >>> .el-input__icon {
        line-height: normal;
    }

    .content >>> .el-input__inner {
        background: none;
        height: 26px;
        line-height: 26px;
    }

    .content >>> .el-date-editor .el-range-input {
        height: 26px;
        background: none;
        font-size: 15px;
        font-weight: bold;
        color: #FFF;
    }

    .content >>> .el-date-editor .el-range-separator {
        height: 26px;
        line-height: 26px;
    }

    .v1 >>> .el-radio {
        color: #FFF;
        margin-right: 10px;
    }
    .v1 >>> .el-radio__label {
        padding-left: 4px;
    }
    .v1 >>> .el-radio__input.is-checked+.el-radio__label {
        color: #85e984;
    }
    

    .custom-table >>> .cell {
        font-size: 12px;
        color: #000000;
        padding: 5px 0px;
    }
    .custom-table >>> th {
        background: linear-gradient(to bottom, #9cd4e3, #33b8db);
    }
    /** 表头样式 */
    .custom-table >>> th.el-table__cell>.cell {
        line-height: 22px;
        padding: 0 0;
        color: #000;
        font-weight: 400;
        border: 1px solid #FFF;
    }
    .custom-table >>> .el-table__cell {
        padding: 0 0;
    }
    /* 修改表格行的鼠标悬停颜色 */
    .custom-table >>> .el-table__body tr:hover>td.el-table__cell {
        background-color: #86e9f9 !important;
    }
    /* 修改表格选中行颜色 */
    .custom-table >>> .el-table__body tr.current-row>td.el-table__cell {
        background-color: #3ebcf6 !important;
    }

</style>