<template>
    <div class="content" v-if="show">
        <div class="warn-tips">
            <span class="msg">{{ msg }}</span>
        </div>
        <span class="times">{{ times+' 秒后自动关闭' }}</span>
        <span class="close-btn" @click="close">X</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false,
            times: 0,               // 倒计时
            msg: '新海滨驾驶台右检测出报警，报警类型为打电话检测报警',
            // 倒计时对象
            timesInterval: null,
        }
    },
    methods: {
        setShow(msg){
            this.msg = msg
            this.times = 10
            this.show = true

            if(this.timesInterval){
                clearInterval(this.timesInterval)
            }
            this.timesInterval = setInterval(()=>{
                this.times--
                console.log(this.times)
                if(this.times<0){
                    clearInterval(this.timesInterval)
                    this.show = false
                }
            }, 1000)
        },

        close(){
            this.show = false
        },
    }
}

</script>

<style lang="scss" scoped>

@keyframes blink {
    0% {
        opacity: 0.95;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0.95;
    }
}

.warn-tips {
    width: 100%;
    height: 50px;
    background-color: rgba($color: #f51c1c, $alpha: 0.9);
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10000;
    animation: blink 1s infinite;
    text-align: center;
    .msg {
        font-size: 20px;
        color: #FFF;
        opacity: 0.9;
        line-height: 50px;
    }
}
.close-btn {
    position: absolute;
    top: 12px;
    right: 15px;
    background-color: #d63232;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    color: #ebeaea;
    cursor: pointer;
    border: 2px solid #FFF;
    font-size: 12px;
    z-index: 10001;
    user-select: none;
}
.times {
    position: absolute;
    top: 14px;
    right: 50px;
    z-index: 10001;
    font-size: 14px;
    color: #FFF;
    user-select: none;
}

</style>