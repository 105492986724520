<template>
  <div class="g-collapse-panel-item">
    <div class="header">
      <div  @click.stop="handlePanelItemClick">
        <i :class="['arrow']">
          <i class="el-icon-caret-bottom" v-if="status" />
          <i class="el-icon-caret-right" v-else />
        </i>
        <span>{{ title }}</span>
      </div>
      <div>
        <slot name="suffix">
        </slot>
      </div>
    </div>
    <transition-collapse>
      <div class="content" v-show="status">
        <div class="inner-content">
          <!-- <slot name="content"></slot> -->
          <slot></slot>
        </div>
      </div>
    </transition-collapse>
  </div>
</template>
  
<script>
import TransitionCollapse from "./transition-collapse.vue";
export default {
  components: { TransitionCollapse },
  // 父组件实例
  inject: ["collapseInstace"],
  props: {
    // 当前面板标识
    name: {
      type: [String, Number],
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      status: true,
    };
  },
  methods: {
    // 点击折叠状态交给父组件处理
    handlePanelItemClick() {
      // this.$parent.toggle(this);
      this.status = !this.status
    },
    // 根据初始状态的value，设置展开项
    init() {
      if (this.name == this.collapseInstace.value) {
        this.status = true;
      }
    },
  },
  mounted() {
    // 初始化
    this.init();
  },
};
</script>
  
<style lang="scss" scoped>
.g-collapse-panel-item {
  margin-bottom: -1px;

  .header {
    height: 38px;
    padding-left: 8px;
    cursor: pointer;
    position: relative;
    // color: #666;
    // border: 1px solid #dcdee2;
    // background-color: #eeeeee;
    font-size: inherit;
    color: #1d2129;
    border: 1px solid inherit;
    background-color: inherit;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // user-select: none;
  }

  .content {
    padding: 0 16px 16px 16px;
    // border-left: 1px solid #dcdee2;
    // border-right: 1px solid #dcdee2;
    // background-color: #f7f7f7;
    color: #1d2129;
    background-color: inherit;

  }

  .arrow {
    display: inline-block;
    width: 10px;
    padding-right: 8px;
    color: #909399;
  }

}
</style>
  