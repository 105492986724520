<template>
    <div class="modal" v-if="visible">
        <div class="modal-content" @click.stop>
            <div class="box-top">
                <span class="box-title">{{ modalTitle }}</span>
                <div class="top-btn">
                    <img alt src="../../assets/img/common/close.png"  @click="close"/>
                </div>
            </div>
            <slot></slot>
            <div class="box-footer" v-if="openFooter">
                <button @click="sure">确认</button>
                <button @click="close">取消</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        modalTitle: {
            type: String,
            default: ''
        },
        openFooter: {
            type: Boolean,
            default: false
        }
    },

    methods: {
        close(){
            this.$emit('update:visible', false)
        },
        sure(){
            this.$emit('sure')
        }
    }
}
</script>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        z-index: 210;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(117, 176, 208, 0.3);
        display: flex;
        align-items: center;
        justify-content: center;
        .modal-content {
            z-index: 220;
            background: linear-gradient(to right,#06397b,#015cc3);
            border: 1px solid #b2c8da;
            border-radius: 5px;
            min-width: 280px;   // 根据当前modal变动
            // margin: 15% auto;
            color: #ffffff;
            .box-top {
                height: 32px;
                text-align: center;
                position: relative;
                border-bottom: 1px solid #b2c8da;
                .box-title {
                    line-height: 30px;
                    font-size: 14px;
                    user-select: none;
                }
                .top-btn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    user-select: none;
                    img {
                        cursor: pointer;
                    }
                }
            }
            .box-content {
                padding: 20px 10px 10px 10px;
            }
            .box-footer {
                display: flex;
                justify-content: space-around;
                padding: 10px 0;
                button {
                    width: 30%;
                    height: 30px;
                    line-height: 30px;
                    border: 1px solid #000;
                    border-radius: 5px;
                    background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
                    cursor: pointer;
                }
            }
        }
    }
</style>