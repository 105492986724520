<template>
    <div v-show="show">
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'BaseTabPane',
    props: {
        name: {
            type: String
        },
        label: {
            type: String,
            default: ''
        }
    },
    inject: ['initTabs'],
    data() {
        return {
            show: true,
        }
    },
    mounted() {
        this.initTabs()
    },
    watch: {
        label() {
            this.initTabs()
        },
        name() {
            this.initTabs()
        }
    }
}
</script>
