<template>
    <div class="content" v-if="show">
        <div class="v1">
            <span class="title">航线列表</span>
            <div class="p1">
                <img alt src="../../assets/img/common/close.png" @click="show=false"/>
            </div>
        </div>
        <div class="menu">
            <span @click="addWay">添加航线</span>
            <span @click="copyWay">复制航线</span>
            <span @click="editWay">编辑</span>
            <span @click="delWay">删除</span>
            <span @click="settingWay">设置</span>
            <!-- <span @click="test5">test5</span> -->
        </div>
        <div class="v2">
            <ul class="table-title" style="cursor: default">
                <li class="c1">序号</li>
                <li class="c2">航线名称</li>
                <li class="c3">航段数</li>
                <li class="c4">是否显示</li>
            </ul>
            <ul v-for="(item, i) in dataList" :key="i" @click="checkedWay(item, i)" :style="{backgroundColor: currWayId==item.id?'#3ebcf6':''}">
                <li class="c1">{{ i+1 }}</li>
                <li class="c2">{{ item.routeName }}</li>
                <li class="c3">{{ item.segmentNum }}</li>
                <li class="c4" @click="clickChecked(i, item)" @click.stop>
                    <img alt :src="require('@/assets/img/common/'+(item.showStatus==0?'open.svg':'hide.svg'))" width="24"/>
                </li>
            </ul>
        </div>

        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" :openFooter="openFooter" @sure="sureModal">
            <div class="box-content">
                {{ modalContent }}
            </div>
        </SswgModal>

        <WayDetail ref="wayDetailRef"></WayDetail>
    </div>
</template>

<script>
import WayDetail from './WayDetail.vue';

import {
    SceneOnewayFairwayItem,
    SceneItemCommon
} from '../../../public/hd/chartbox';

import { llPointToENPoint } from '@/utils';

export default {
    components: {
        WayDetail
    },
    // 父级提供的
    inject: ['instanceProvide', 'app'],
    // 本组件提供给子组件
    provide() {
        return {
            parent: this,
        }
    },
    data() {
        return {
            show: false,
            dataList: [],
            
            currWayId: 0,       // 当前航线id
            currWayName: '',
            currWayPoints: '',
            currWayIndex: 0,    // 当前航线索引 删除时使用

            modalVisible: false,
            modalTitle: '提示',
            openFooter: true,
            modalContent: '',

            // 是否首次加载
            firstLoading: true
        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        },
    },
    mounted(){
        console.log('mounted>>>> WayList.vue')
        // F5刷新场景
        if(this.hasLogin){
            this.queryDataList()
        }
    },
    methods: {

        test5(){
            console.log(this.instanceProvide.wayLayer.items())
        },

        // 查询数据列表
        queryDataList(){
            this.sswgPost('/route/fetchRouteList', {agentId: localStorage.getItem('login_agent_id')}).then(res => {
                if(res){
                    console.log(res)
                    this.dataList = res.data
                    // 画航线(首次加载，延时5秒)
                    if(this.firstLoading){
                        setTimeout(() => {
                            this.drawWay()
                        }, 4000)
                        this.firstLoading = false
                    }else{
                        this.drawWay()
                    }
                }
            })
        },

        // 画航线
        drawWay(){
            // 先清除
            this.instanceProvide.wayLayer.clearItems()
            for(let i=0; i<this.dataList.length; i++){
                this.addWayItemForLayer(this.dataList[i].waypointList, this.dataList[i].showStatus, this.dataList[i].routeName)
            }
            // 添加完多条航线之后，更新map
            this.instanceProvide.map.update()
        },

        /**
         * 添加单条航线
         * @param wayPointList  一条航线的航点
         * @param showStatus    显示状态
         * @param routeName     航线名称
         */
        addWayItemForLayer(wayPointList, showStatus, routeName){
            let b = showStatus==0?true:false
            // 单向航道
            const item = new SceneOnewayFairwayItem({
                text: routeName,
                fillColor: '#f8ff00',
            })
            for(let i=0; i<wayPointList.length; i++){
                const pos = llPointToENPoint(wayPointList[i].lot, wayPointList[i].lat)
                item.addPoint(pos, 80, 80)
            }
            item.setVisible(b)
            item.setState(SceneItemCommon.ItemState.normal)
            this.instanceProvide.wayLayer.addItem(item)
        },

        // 选择航线
        checkedWay(item, i){
            console.log(item)
            this.currWayId = item.id
            this.currWayName = item.routeName
            this.currWayPoints = item.waypoints
            this.currWayIndex = i
        },

        /**
         * 显示、隐藏
         * @param i     航线索引
         * @param item  
         */
        clickChecked(i, item){
            // 新状态 相反
            let status = item.showStatus==0?1:0
            // 控制航线显示与隐藏
            let visible = item.showStatus==0?false:true
            this.instanceProvide.wayLayer.items()[i].setVisible(visible)
            // 修改图标
            item.showStatus = status
            // 状态保存到数据库
            this.sswgPost('/route/updateRoute', {id: item.id, showStatus: status}, true, 'json').then(res => {})
        },

        // 设置航线列表模块显示、隐藏
        setShow(show){
            if(show==undefined){
                let b = this.show
                if(!b){
                    this.$store.commit('updateShowShipList', 0)
                    this.queryDataList()
                }
                this.show = !b
            }else{
                this.show = show
            }
        },

        // 添加航线
        addWay(){
            this.currWayId = 0
            this.$refs.wayDetailRef.setShow()
            this.$refs.wayDetailRef.setCurrOperate('add')
        },

        // 复制航线
        copyWay(){
            this.$refs.wayDetailRef.close()
            if(this.currWayId==0){
                this.$message.warning('请先选中复制的航线')
                return
            }
            this.editWay()
            // 修改为copy
            this.$refs.wayDetailRef.setCurrOperate('copy')
        },

        //编辑航线
        editWay(){
            this.$refs.wayDetailRef.close()
            if(this.currWayId==0){
                this.$message.warning('请先选中编辑的航线')
                return
            }
            this.$refs.wayDetailRef.setCurrOperate('edit')
            this.$refs.wayDetailRef.setEditInfo(this.currWayId, this.currWayName, this.currWayPoints)
            this.$refs.wayDetailRef.setShow() // 必须放在最后
        },

        // 删除航线
        delWay(){
            this.$refs.wayDetailRef.close()
            if(this.currWayId==0){
                this.$message.warning('请先选中删除的航线')
                return
            }
            this.modalVisible = true
            this.modalContent = '确认删除航线【'+this.currWayName+'】吗？'
        },

        // 确认modal
        sureModal(){
            let id = this.currWayId
            let params = {
                agentId: localStorage.getItem('login_agent_id'),
                id: id 
            }
            this.sswgPost('/route/deleteRoute', params, true, 'json').then(res => {
                this.currWayId = 0
                this.modalVisible = false
                // this.instanceProvide.wayLayer.items()[this.currWayIndex].delete()
                // 更新当前航线列表
                // this.dataList = this.dataList.filter(obj => {
                //     return obj.id !=id 
                // })
                this.queryDataList()
            })
        },

        settingWay(){
            this.$message.warning('待完善')
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        width: 360px;
        height: calc(100% - 118px);
        background-color: #1686d8;
        border: 1px solid #000;
        border-radius: 5px;
        .v1 {
            width: 360px;
            height: 30px;
            text-align: center;
            position: relative;
            .title {
                color: #FFF;
                font-weight: bold;
                line-height: 30px;
                font-size: 15px;
            }
            .p1 {
                background-color: #1686d8;
                position: absolute;
                right: 5px;
                top: 5px;
                img {
                    cursor: pointer;
                }
            }
        }

        .menu {
            // display: flex;
            border-top: 1px solid #c2c0c0;
            padding: 10px 4px;

            span {
                padding: 2px 6px;
                border: 1px solid #85e984;
                border-radius: 5px;
                color: #FFF;
                margin-right: 10px;
                cursor: pointer;
                user-select: none;
                font-size: 12px;
            }
        }

        .v2 {
            background-color: #e5efff;
            height: calc(100% - 60px);
            overflow-y: auto;
            border-bottom: 1px solid #000000;
            ul {
                display: flex;
                text-align: center;
                line-height: 26px;
                font-size: 14px;
                color: #000;
                .c1 {
                    width: 50px;
                }
                .c2 {
                    width: 166px;
                }
                .c3 {
                    width: 70px;
                }
                .c4 {
                    width: 70px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            ul:hover {
                background-color: #86e9f9;
                cursor: pointer;
            }
            .table-title {
                background: url(../../assets/img/common/table_bg.png) left top / 100% 100% no-repeat #1832a8;
                color: #FFF;
                font-size: 12px;
                line-height: 16px;
                user-select: none;
            }
        }
    }

</style>