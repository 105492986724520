// 引入自定义的全局组件
import BaseModal from './base-modal/index.vue';
import BaseTabs from './base-tabs/base-tabs.vue';
import BaseTabPane from './base-tabs/base-tab-pane.vue';
// import BaseTabs from './tabs/tabs.vue';
// import BaseTabPane from './tabs/pane.vue';
import { Panel, Collapse } from './base-collapse'

import SswgModal from './sswg/sswg-modal.vue'

// 默认导出插件
export default {
	install: function (app) {
		app.component('BaseModal', BaseModal);
		app.component('BaseCollapse', Collapse);
		app.component('BaseCollapsePanel', Panel);
		app.component('BaseTabs', BaseTabs);
		app.component('BaseTabPane', BaseTabPane);

		app.component('SswgModal', SswgModal);
	}
}
