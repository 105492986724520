import { SceneItem, MercatorTransform, PainterPath, Pen, Brush, Font, ScenePointItem } from './chartbox'

class CustomTargetItem extends ScenePointItem {

    m_options = {
        center: [13167663.15722572, 2801921.4494793364],
        shipScale: 5,
        cog: -320,                      // 航向
        sog: 20,                        // 航速
        hdg: 10,                        // 航艏向 （无数据）
        fillType: 1,
        fillColor: '#2ECC71',
        strokeColor: '#000000',
        strokeType: 1,
        strokeWeight: 1,
        labelFillColor: '#ffffff',      //Label填充颜色
        labelFillType: 1,               //Label填充类型
        titleScale: 6,                  //船名显示层级
    }

    isSelected = false
    polygon = [[-5, 8], [5, 8], [0, -8]];
    angle = 0
    
    // length = 40             // 船长度
    // width = 9               // 船宽度
    length = 200             // 船长度
    width = 40               // 船宽度

    shipId = 0              // 船ID
    merchantId = ''         // 店铺ID

    constructor(options) {
        super(options);
        this.setPosition(options.center)
        this.transform = new MercatorTransform()
        this.m_options = Object.assign(this.m_options, options)

        this.textBound = this.measureText(this.m_options.text, {
            fontSize: this.m_options.fontSize
        })

        if (options.width && options.length) {
            this.width = options.width
            this.length = options.length

            this.setPixMargin(this.width + this.length);
        } else {
            this.setPixMargin(this.width + this.length);
        }
    }

    measureText(text, options) {
        const { fontSize = 14, fontFamily = "Microsoft YaHei" } = options;
        var span = document.createElement("div");
        var result = {
            width: span.offsetWidth,
            height: span.offsetHeight,
        };

        span.style.visibility = "hidden";
        span.style.fontSize = `${fontSize}pt`;
        span.style.fontFamily = fontFamily;
        span.style.display = "inline-block";
        // span.style.opacity = "0.5";

        var result = {
            width: span.offsetWidth,
            height: span.offsetHeight,
        };
        if (typeof span.textContent != "undefined") {
            span.textContent = text;
        } else {
            span.innerText = text;
        }
        document.body.appendChild(span);
        result.width = Math.ceil(span.getBoundingClientRect().width); // 获取文本宽度
        result.height = Math.ceil(span.getBoundingClientRect().height); // 获取文本高度
        document.body.removeChild(span); // 移除span元素

        return result;
    }


    setSelected(bool) {
        this.isSelected = bool
    }

    setOptions(options) {
        Object.assign(this.m_options, options)
        this.update()
    }

    setCenter(center) {
        this.m_options.center = center
        this.setPosition(center)
    }

    setShipIdAndMerchantId(shipId, merchantId){
        this.shipId = shipId
        this.merchantId = merchantId
    }

    /**
     * 根据websocket数据更新船舶信息
     * @param {*} center    中心点坐标
     * @param {*} text      Label文本
     * @param {*} cog       航向
     * @param {*} sog       航速
     * @param {*} hdg       航艏向
     */
    updateShipInfoByWebSocketData(center, text, cog, sog){
        this.m_options.text = text
        this.m_options.cog = cog
        this.m_options.sog = sog
        this.m_options.center = center
        this.setPosition(center)
    }

    rotatePolygon(polygon, angle) {
        // 将角度转换为弧度
        var radians = angle * (Math.PI / 180);
        var cos = Math.cos(radians);
        var sin = Math.sin(radians);
        const newPolygon = []

        for (var i = 0; i < polygon.length; i++) {
            var x = polygon[i][0];
            var y = polygon[i][1];

            // 应用旋转公式
            newPolygon.push([x * cos - y * sin, x * sin + y * cos]);
        }

        return newPolygon;
    }


    boundingBox() { //外接矩形(用于绘制时快速定位元素位置)
        const [centerX, centerY] = this.getPosition()
        return [[centerX, centerY, 1, 1]];
    }

    shape(viewport) {
        const scaleFactor = viewport.getScale()

        const painterPath = new PainterPath();
        const [centerX, centerY] = viewport.enPointToPixelPoint(this.getPosition())
        let scale = Math.ceil(1 / viewport.getPhyScale());

        // console.log(scale)

        if (scale >= 300000) {
            var l_polygon = viewport.pixelRectToENPolygon(-5 + centerX, -5 + centerY, 8, 8);
            painterPath.addPolygon(l_polygon);
        } else if (scale < 300000 && scale >= 24000) {
            var l_polygon = [];
            for (const p of this.rotatePolygon(this.polygon, this.m_options.cog)) {
                l_polygon.push([p[0] + centerX, p[1] + centerY]);
            }
            painterPath.addPolygon(viewport.pixelPolygonToENPolygon(l_polygon));
        } else {
            var l_polygon = [];
            for (const p of this.rotatePolygon(this.createShipPath(scaleFactor), this.m_options.cog)) {
                l_polygon.push([p[0] + centerX, p[1] + centerY]);
            }
            painterPath.addPolygon(viewport.pixelPolygonToENPolygon(l_polygon));
        }

        return [painterPath];
    }

    // 创建船形路径
    createShipPath(scaleFactor) {
        return [
            [this.width * scaleFactor / 2, -this.length * scaleFactor],
            [this.width * scaleFactor, -this.length * scaleFactor * (3 / 4)],
            [this.width * scaleFactor, this.length * scaleFactor],
            [this.width * scaleFactor / 2, this.length * scaleFactor],
            [-this.width * scaleFactor, this.length * scaleFactor],
            [-this.width * scaleFactor, -this.length * scaleFactor * (3 / 4)],
            [-this.width * scaleFactor / 2, -this.length * scaleFactor]
        ];
    }

    render(painter, viewport) {

        

        // 计算三角形的顶点坐标
        const position = this.getPosition()
        const [centerX, centerY] = viewport.enPointToPixelPoint(position)

        painter.save();
        painter.setRenderHit(0);
        painter.translate(centerX, centerY);
        let scale = Math.ceil(1 / viewport.getPhyScale());
        const scaleFactor = viewport.getScale()

        // console.log('scale= ' +scale)
        // console.log('render scale>>>>>'+scale+'   系数:'+scaleFactor) 

        if (this.isSelected) {
            painter.setPen(new Pen({
                width: 2,
                color: '#ff0000',
                style: 2,
            }));
            // painter.setBrush(new Brush({
            //     color: '#00000000',
            // }));
            // painter.drawEllipse([-14, -14, 28, 28]);



            if (scale >= 24000) {
                //左上
                painter.drawLine([-14, -10], [-14, -14]);
                painter.drawLine([-14, -14], [-10, -14]);

                //右下
                painter.drawLine([-14, 10], [-14, 14]);
                painter.drawLine([-14, 14], [-10, 14]);

                //左下
                painter.drawLine([10, 14], [14, 14]);
                painter.drawLine([14, 14], [14, 10]);

                //右上
                painter.drawLine([10, -14], [14, -14]);
                painter.drawLine([14, -14], [14, -10]);
            } else if (this.width && this.length) {
                const maxLength = Math.max(this.width, this.length) * scaleFactor
                //左上
                painter.drawLine([-14 - maxLength, -10 - maxLength], [-14 - maxLength, -14 - maxLength]);
                painter.drawLine([-14 - maxLength, -14 - maxLength], [-10 - maxLength, -14 - maxLength]);

                //右下
                painter.drawLine([-14 - maxLength, 10 + maxLength], [-14 - maxLength, 14 + maxLength]);
                painter.drawLine([-14 - maxLength, 14 + maxLength], [-10 - maxLength, 14 + maxLength]);

                //左下
                painter.drawLine([10 + maxLength, 14 + maxLength], [14 + maxLength, 14 + maxLength]);
                painter.drawLine([14 + maxLength, 14 + maxLength], [14 + maxLength, 10 + maxLength]);

                //右上
                painter.drawLine([10 + maxLength, -14 - maxLength], [14 + maxLength, -14 - maxLength]);
                painter.drawLine([14 + maxLength, -14 - maxLength], [14 + maxLength, -10 - maxLength]);
            }
        }

        if (scale >= 300000) {
            //判断指定层级下显示的样式
            painter.setPen(new Pen({
                width: this.m_options.strokeWeight,
                color: this.m_options.strokeColor,
                style: this.m_options.strokeType,
            }));
            painter.setBrush(new Brush({
                color: this.m_options.fillColor,
                style: this.m_options.fillType,
            }));
            painter.drawEllipse([-4, -4, 8, 8]);
        } 
        // else if (scale < 300000 && scale >= 24000) { //船舶样式有问题，暂时不显示
        else if (scale < 300000) {
            // 修改线条颜色,宽度,类型
            painter.setPen(new Pen({
                width: this.m_options.strokeWeight,
                color: this.m_options.strokeColor,
                style: this.m_options.strokeType,
            }));
            //设置文字颜色,大小
            // painter.setFont(new Font({
            //     pointSize: 10,
            // }));

            // 设置填充颜色,类型
            painter.setBrush(new Brush({
                color: this.m_options.fillColor,
                style: this.m_options.fillType,
            }));

            painter.save();
            painter.rotate(this.m_options.cog) // 设置旋转角度
            painter.drawPolygon(this.polygon);
            painter.restore();

            //绘制航向线
            const l_transform = viewport.getMercatorTransform();
            const [refLon, refLat] = l_transform.enPointToLLPoint(position[0], position[1]);
            var l_point = l_transform.llPointFromAzimuthAndDistance(refLon, refLat, this.m_options.cog, this.m_options.sog * (60 / 3600.) * 1852);
            l_point = l_transform.llPointToENPoint(l_point[0], l_point[1]);
            l_point = viewport.enPointToPixelPoint(l_point);
            painter.drawLine([0, 0], [l_point[0] - centerX, l_point[1] - centerY]);


            //绘制艏向线 (2024-11-01 黄生 艏向无数据，故不绘制)
            // var l_point2 = l_transform.llPointFromAzimuthAndDistance(refLon, refLat, this.m_options.hdg, this.m_options.sog * (30 / 3600.) * 1852);
            // l_point2 = l_transform.llPointToENPoint(l_point2[0], l_point2[1]);
            // l_point2 = viewport.enPointToPixelPoint(l_point2);
            // painter.drawLine([0, 0], [l_point2[0] - centerX, l_point2[1] - centerY]);



            //绘制文本框
            this.drawLabel(viewport, painter)

        } else if (this.width && this.length) {
            //判断指定层级下显示的样式
            painter.setPen(new Pen({
                width: this.m_options.strokeWeight,
                color: this.m_options.strokeColor,
                style: this.m_options.strokeType,
            }));
            painter.setBrush(new Brush({
                color: this.m_options.fillColor,
                style: this.m_options.fillType,
            }));
            // painter.drawEllipse([-4, -4, 8, 8]);

            painter.save();
            painter.rotate(this.m_options.cog) // 设置旋转角度
            painter.drawPolygon(this.createShipPath(scaleFactor));
            painter.restore();
        }
        painter.restore();
    }

    drawLabel(viewport, painter) {
        painter.save();
        if (viewport.getZoom() > this.m_options.titleScale) {
            //设置Label的连接线颜色
            painter.setPen(new Pen({
                width: 1,
                color: '#ffffff',
                style: 1,
            }));
            // 绘制Label连接线
            painter.drawLine([0, 0], [30, -30])

            // 设置文字大小
            painter.setFont(new Font({
                pointSize: this.m_options.fontSize,
            }));
            // 设置文字颜色
            painter.setPen(new Pen({
                color: this.m_options.textColor
            }));

            //设置Label框的样式
            painter.setBrush(new Brush({
                color: this.m_options.labelFillColor,
                style: this.m_options.labelFillType,
            }));
            var l_box = painter.boundingRectTextOption(0, 0, 800, 500, this.m_options.text, 2, 1, 0);
            //绘制Label框
            painter.drawRect([30 - 4, -(l_box[3] / 2) - 30 - 2, l_box[2] + 8, l_box[3]]);


            painter.drawTextOption(30, -(l_box[3] / 2) - 30, l_box[2] + 8, l_box[3], this.m_options.text, 2, 1, 0)
            //绘制文本
            // painter.drawText((30 + 4), (this.textBound.height / 2 - 31), this.m_options.text);
        }
        painter.restore();
    }
}


export default CustomTargetItem