<template>
    <div class="content" v-if="show">
        <div class="v1">
            <div class="modal-content" @click.stop>
                <div class="box-top">
                    <span class="box-title">航点</span>
                </div>
                <div class="box-content">
                    <ul class="table-title" style="cursor: default;">
                        <li class="c1">序号</li>
                        <li class="c2">航点名称</li>
                        <li class="w60"></li>
                    </ul>
                    <ul v-for="(item, i) in dataList" :key="i">
                        <li class="c1">{{ i+1 }}</li>
                        <li class="c2">{{ item.label }}</li>
                        <li class="w60">
                            <span v-if="dataList2.some(obj=>obj.id===item.id)"></span>
                            <span v-else @click="choose(item)" style="padding: 2px 10px; cursor: pointer;">选择</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="v1" style="right: 365px; width: 280px;">
            <div class="modal-content" @click.stop>
                <div class="box-top">
                    <span class="box-title">{{ boxTitle }}</span>
                    <div class="top-btn">
                        <img alt src="../../assets/img/common/close.png" @click="close"/>
                    </div>
                </div>
                <div class="box-content" style="min-height:200px; max-height: 395px;">
                    <ul class="table-title" style="cursor: default;">
                        <li class="c1">序号</li>
                        <li class="c2">航点名称</li>
                        <li class="c3"></li>
                    </ul>
                    <ul v-for="(item, i) in dataList2" :key="i">
                        <li class="c1">{{ i+1 }}</li>
                        <li class="c2">{{ item.label }}</li>
                        <li class="c3">
                            <i class="el-icon-top" title="上移" @click="moveWayPoint(i, 'top')"></i>
                            <i class="el-icon-bottom" title="下移" @click="moveWayPoint(i, 'bottom')"></i>
                            <i class="el-icon-remove-outline" title="删除" @click="delWayPoint(item.id)" style="color: brown;"></i>
                        </li>
                    </ul>
                </div>
                <el-form :model="dg.p" label-width="70px" style="padding: 10px 5px;">
                    <el-form-item label="航线名称">
                        <el-input v-model="dg.p.routeName" clearable maxlength="40" placeholder="请输入航线名称"></el-input>
                    </el-form-item>
                </el-form>
                <div class="box-footer">
                    <button @click="sure">确认</button>
                    <button @click="close">取消</button>
                </div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    inject: ['parent'],
    data() {
        return {
            show: false,
            dataList: [],
            dg: {
                p: {
                    routeName: '',
                    waypoints: ''
                }
            },
            currWayPointId: 0,

            currOperate: '', // 当前操作 add edit del
            
            // 航线包含的航点列表
            dataList2: [],
            chooseIds: [],
            // 航线名称
            routeName: '',
            // 当前编辑航线id
            currWayId: 0,
            // 当前选择的航点id  编辑的时候用到
            currWayPoints: '',
            boxTitle: '添加航线'
        }
    },

    methods: {

        // 数组移动元素
        moveElement(arr, fromIndex, toIndex) {
            const element = arr.splice(fromIndex, 1)[0];
            arr.splice(toIndex, 0, element);
            return arr;
        },

        // 移动航点
        moveWayPoint(i, direction){
            if(direction=='top' && i>0){
                this.moveElement(this.dataList2, i, i-1)
            }else if(direction=='bottom' && i<this.dataList2.length){
                this.moveElement(this.dataList2, i, i+1)
            }
        },

        // 删除航点
        delWayPoint(id){
            this.dataList2 = this.dataList2.filter(obj=>{
                return obj.id!=id
            })
        },

        // 查询航点
        queryDataList(){
            this.sswgPost('/waypoint/fetchWaypointList', {agentId: localStorage.getItem('login_agent_id')}).then(res => {
                console.log(res)
                this.dataList = res.data
                // 填充数据
                if(this.currWayPoints.length>0){
                    this.convertDataList2ByWaypoints(this.currWayPoints)
                }
            })
        },

        // 选择航点
        choose(item){
            this.dataList2.push(item)
        },

        // 设置当前操作
        setCurrOperate(oper){
            this.currOperate = oper
            if(oper=='add'){
                this.boxTitle = '添加航线'
            }else if(oper=='edit'){
                this.boxTitle = '编辑航线'
            }else if(oper=='copy'){
                this.boxTitle = '复制添加航线'
            }
        },

        /**
         * 设置编辑时的信息
         * @param wayId         航线id
         * @param routeName     航线名称
         * @param waypoints     包含的航点id
         */
        setEditInfo(wayId, routeName, waypoints){
            this.currWayId = wayId
            this.dg.p.routeName = routeName
            this.currWayPoints = waypoints

            // 填充数据
            // this.convertDataList2ByWaypoints(waypoints)
        },

        // 根据航点填充dataList2
        convertDataList2ByWaypoints(waypoints){
            let ps = waypoints.split(',')
            for(let id of ps){
                for(let i=0; i<this.dataList.length; i++){
                    if(id == this.dataList[i].id){
                        this.dataList2.push(this.dataList[i])
                        break
                    }
                }
            }
        },

        // 确认添加或修改
        sure(){
            if(!this.dg.p.routeName){
                this.$message.warning('请输入航线名称')
            }else if(this.dataList2.length<2){
                this.$message.warning('至少选择两个航点')
            }else if(this.currOperate=='add' || this.currOperate=='copy'){
                this.dg.p.agentId = localStorage.getItem('login_agent_id')
                // 拼接id
                let ids = this.dataList2.map(obj => obj.id).join(',')
                let params = {
                    agentId: localStorage.getItem('login_agent_id'),
                    routeName: this.dg.p.routeName,
                    waypoints: ids
                }
                this.sswgPost('/route/addRoute', params, true, 'json').then(res => {
                    this.$message.success('添加成功')
                    this.parent.queryDataList()
                    this.close()
                })
            }else if(this.currOperate=='edit'){
                let ids = this.dataList2.map(obj => obj.id).join(',')
                let param = {
                    id: this.currWayId,
                    routeName: this.dg.p.routeName,
                    waypoints: ids
                }
                this.sswgPost('/route/updateRoute', param, true, 'json').then(res => {
                    this.$message.success('编辑成功')
                    this.parent.queryDataList()
                    this.close()
                })
            }
        },

        close(){
            this.show = false
            this.dg.p.routeName = ''
            this.currOperate = ''
            this.dataList2 = []
            this.currWayId = 0
            this.currWayPoints = ''
        },

        setShow(){
            if(!this.show){
                this.queryDataList()
                this.show = true
            }
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        .v1 {
            position: absolute;
            z-index: 210;
            right: 650px;
            top: 6px;
            .modal-content {
                z-index: 220;
                background: linear-gradient(to right,#06397b,#015cc3);
                border: 1px solid #b2c8da;
                border-radius: 5px;
                min-width: 200px;   // 根据当前modal变动
                color: #ffffff;
                .box-top {
                    height: 32px;
                    text-align: center;
                    position: relative;
                    border-bottom: 1px solid #b2c8da;
                    .box-title {
                        line-height: 30px;
                        font-size: 14px;
                        user-select: none;
                    }
                    .top-btn {
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        user-select: none;
                        img {
                            cursor: pointer;
                        }
                    }
                }
                .box-content {
                    margin: 5px;
                    background-color: #FFFFFF;
                    max-height: 500px;
                    overflow-y: auto;
                    ul {
                        display: flex;
                        text-align: center;
                        line-height: 22px;
                        font-size: 12px;
                        color: #000;
                        border-bottom: 1px dashed rgb(180, 179, 179);
                        .c1 {
                            width: 40px;
                        }
                        .c2 {
                            width: 120px;
                        }
                        .c3 {
                            width: 90px;
                            i {
                                font-weight: bold;
                                font-size: 14px;
                                width: 30px;
                                color: #2f752e;
                                cursor: pointer;
                            }
                        }
                        .w60 {
                            width: 60px;
                        }

                    }
                    ul:hover {
                        background-color: #86e9f9;
                    }
                    .table-title {
                        color: #FFF;
                        font-size: 12px;
                        line-height: 20px;
                        user-select: none;
                        background-color: #0393ed;
                    }
                    .table-title:hover{
                        background-color: #0393ed;
                    }
                }
            }
        }

        .box-footer {
            display: flex;
            justify-content: space-around;
            padding: 10px 0;
            button {
                width: 22%;
                height: 24px;
                line-height: 24px;
                border: 1px solid #000;
                border-radius: 5px;
                background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
                cursor: pointer;
            }
        }
    }
</style>

<style scoped>
    .modal-content >>> .el-form-item {
        margin-bottom: 0px;
    }
    .modal-content >>> .el-form-item__label {
        color: #FFFFFF;
    }
    .modal-content >>> input.el-input__inner {
        font-size: 15px;
        font-weight: bold;
        height: 26px;
        line-height: 26px;
        background: none;
        color: #FFFFFF;
    }
    
    /* 自定义横向滚动条样式 */
    /* .box-content::-webkit-scrollbar {
        width: 10px;
    }
    .box-content::-webkit-scrollbar-thumb {
        background-color: #85e984;
        border-radius: 4px;
    } */
</style>

