import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import directives from '@/utils/directives'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import layer from 'vue-layer'
import 'vue-layer/lib/vue-layer.css'

import globalComponents from '@/components/index'

import { sswgGet, sswgPost, sswgDel } from './sswg/sswgApi'
import { baseUrl, baseApi, webSocketUrl, baseResourceUrl } from './sswg/config'

import { parseTime } from './utils/sswgsz'

// 全局方法挂载
Vue.prototype.$store = store
Vue.prototype.sswgGet = sswgGet
Vue.prototype.sswgPost = sswgPost
Vue.prototype.sswgDel = sswgDel
Vue.prototype.webSocketUrl = webSocketUrl
Vue.prototype.baseApi = baseApi
Vue.prototype.$baseUrl = baseUrl
Vue.prototype.$baseResourceUrl = baseResourceUrl


Vue.prototype.parseTime = parseTime

Vue.prototype.$layer = layer(Vue)

Vue.config.productionTip = false

Vue.use(directives)

Vue.use(ElementUI);
Vue.use(globalComponents)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
