<template>
    <div class="content" v-if="show">
        <SswgModal :visible.sync="modalVisibleStat" modalTitle="航班信息">
            <div class="v1">
                <div class="v101">
                    <el-select v-model="currShip" value-key="id" filterable placeholder="请选择船名" style="width: 160px;" @change="eventChangeShip">
                        <el-option v-for="item in shipList" :key="item.id" :label="item.name" :value="item"></el-option>
                    </el-select>
                    <span style="margin-left: 10px;">入库时间范围：</span>
                    <el-date-picker v-model="value2" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" style="width: 260px;">
                    </el-date-picker>
                </div>
                <div class="v102">
                    <span class="btn" @click="queryStat">查询</span>
                    <span class="btn" @click="exportExcel">导出Excel</span>
                </div>
            </div>
            <div class="v2">
                <span class="btn btn2" @click="validateShipPassword" v-if="!hasValidate">数据录入</span>
                <span class="btn btn2" @click="add" v-else>添加</span>
            </div>
            <div class="v4">
                <el-table ref="elTable" :data="dataList" stripe style="width: 100%;" highlight-current-row class="custom-table">
                    <el-table-column type="index" label="序号" width="50" align="center"/>
                    <el-table-column prop="startPortName" label="始发港" width="80" align="center"/>
                    <el-table-column prop="throughPortName" label="途经港" width="80" align="center"/>
                    <el-table-column prop="endPortName" label="到达港" width="80" align="center"/>
                    <el-table-column prop="numOfSailor" label="船员人数" width="60" align="center"/>
                    <el-table-column prop="numOfVipAdult" label="头等舱成人数" width="45" align="center"/>
                    <el-table-column prop="numOfVipChild" label="头等舱儿童数" width="45" align="center"/>
                    <el-table-column prop="numOfVipBaby" label="头等舱婴儿数" width="45" align="center"/>
                    <el-table-column prop="numOfNormalAdult" label="普通舱成人数" width="45" align="center"/>
                    <el-table-column prop="numOfNormalChild" label="普通舱儿童数" width="45" align="center"/>
                    <el-table-column prop="numOfNormalBaby" label="普通舱婴儿数" width="45" align="center"/>
                    <el-table-column prop="numOfPassenger" label="旅客总数" width="60" align="center"/>
                    <el-table-column prop="sailTime" label="开航时间" width="140" align="center"/>
                    <el-table-column prop="voyageNumber" label="航班号" width="80" align="center"/>
                    <el-table-column prop="createdTime" label="入库时间" width="140" align="center"/>
                    <el-table-column prop="createdName" label="录入人" width="70" align="center"/>
                    <el-table-column prop="remark" label="备注" width="70" align="center"/>
                    <el-table-column label="操作" width="100" align="center">
                        <template slot-scope="scope">
                            <span class="btn3" @click="edit(scope.row)" v-if="hasValidate">编辑</span>
                            <span class="btn3" @click="del(scope.row.id)" v-if="hasValidate">删除</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </SswgModal>

        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" :openFooter="openFooter" @sure="sureModal">
            <div class="c1" v-if="currOperate=='add' || currOperate=='edit'">
                <span class="required">始发港</span>
                <el-select v-model="portFrom.value" filterable clearable placeholder="请选择" style="width: 170px;">
                    <el-option v-for="item in portFrom.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <span style="margin-left: 20px;">录入人</span>
                <el-input v-model="dg.p.createdName" clearable maxlength="20" style="width: 180px;"></el-input>
                <span class="no-star">途径港</span>
                <el-select v-model="portThrough.value" filterable clearable placeholder="请选择" style="width: 170px;">
                    <el-option v-for="item in portThrough.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <span style="margin-left: 20px;">普通舱成人数</span>
                <el-input v-model="dg.p.numOfNormalAdult" type="number" clearable maxlength="20" style="width: 180px;"></el-input>
                <span>到达港</span>
                <el-select v-model="portTo.value" filterable clearable placeholder="请选择" style="width: 170px;">
                    <el-option v-for="item in portTo.list" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
                <span style="margin-left: 20px;">普通舱儿童数</span>
                <el-input v-model="dg.p.numOfNormalChild" type="number" clearable max="10000" style="width: 180px;"></el-input>
                <span>船员人数</span>
                <el-input v-model="dg.p.numOfSailor" type="number" clearable min="0" max="10000" style="width: 170px;"></el-input>
                <span style="margin-left: 20px;">普通舱婴儿数</span>
                <el-input v-model="dg.p.numOfNormalBaby" type="number" clearable max="10000" style="width: 180px;"></el-input>
                <span>头等舱成人数</span>
                <el-input v-model="dg.p.numOfVipAdult" type="number" clearable max="10000" style="width: 170px;"></el-input>
                <span style="margin-left: 20px;">航班号</span>
                <el-input v-model="dg.p.voyageNumber" clearable maxlength="20" style="width: 180px;"></el-input>
                <span>头等舱儿童数</span>
                <el-input v-model="dg.p.numOfVipChild" type="number" clearable max="10000" style="width: 170px;"></el-input>
                <span style="margin-left: 20px;">开航时间</span>
                <el-date-picker v-model="dg.p.sailTime" type="datetime" placeholder="开航时间" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" style="width: 180px;"></el-date-picker>
                <span>头等舱婴儿数</span>
                <el-input v-model="dg.p.numOfVipBaby" type="number" clearable max="10000" style="width: 170px;"></el-input>
                <span style="margin-left: 20px;" class="no-star">备注</span>
                <el-input v-model="dg.p.remark" clearable maxlength="20" style="width: 180px;"></el-input>
            </div>
            <div class="box-content" v-else-if="currOperate=='del'">
                {{ modalContent }}
            </div>
            <div class="box-content" v-else-if="currOperate=='validate'">
                <span style="padding-left: 10px; padding-right: 10px;">船舶静态密码</span>
                <el-input v-model="shipPassword" clearable maxlength="40" style="width: 180px;"></el-input>
            </div>
        </SswgModal>
    </div>
</template>

<script>

import { saveAs } from 'file-saver';

export default {
    // 父级提供的
    inject: ['app'],
    data() {
        return {
            show: false,
            dataList: [],

            shipList: [],
            currShipId: '',
            currShip: {},

            modalVisibleStat: false,

            modalVisible: false,
            modalTitle: '提示',
            openFooter: true,
            modalContent: '',

            currOperate: '', // 当前操作 add edit del validate(验证密码)

            radio: 2,
            value2: [new Date(), new Date()],

            // 按日统计
            beginDate: this.parseTime(new Date()),

            // 始发港
            portFrom: {
                list: [],
                value: ''
            },

            portThrough: {
                list: [],
                value: ''
            },

            // 目的港
            portTo: {
                list: [],
                value: ''
            },

            dg: {
                currVoyageId: '',               // 当前编辑或删除的航班id
                p: {
                    numOfSailor: undefined,
                    numOfVipAdult: undefined,
                    numOfVipChild: undefined,
                    numOfVipBaby: undefined,

                    numOfNormalAdult: undefined,
                    numOfNormalChild: undefined,
                    numOfNormalBaby: undefined,

                    createdName: '',
                    voyageNumber: '',
                    remark: '',
                    sailTime: undefined
                }
            },

            // 当前是否已经验证
            hasValidate: false,
            shipPassword: ''
        }
    },
    methods: {

        eventChangeShip(item){
            this.currShipId = item.id
        },

        resetDg(){
            this.dg.p.id = undefined

            this.dg.p.shipInfoId = undefined

            this.portFrom.value = undefined
            this.portThrough.value = undefined
            this.portTo.value = undefined

            this.dg.p.starPortId = 0,
            this.dg.p.startPortName = ''
            this.dg.p.throughPortId = 0
            this.dg.p.throughPortName = ''
            this.dg.p.endPortId = 0
            this.dg.p.endPortName = ''

            this.dg.p.numOfSailor = undefined
            this.dg.p.numOfVipAdult = undefined
            this.dg.p.numOfVipChild = undefined
            this.dg.p.numOfVipBaby = undefined

            this.dg.p.numOfNormalAdult = undefined
            this.dg.p.numOfNormalChild = undefined
            this.dg.p.numOfNormalBaby = undefined

            this.dg.p.createdName = ''
            this.dg.p.voyageNumber = ''
            this.dg.p.remark = ''

            this.dg.p.sailTime = undefined
        },

        // 查询统计
        queryStat(){
            if(!this.currShipId){
                this.$message.warning('请选择船名')
                return
            }
            this.queryDataList()
        },

        convertDataList(){
            let arr = []
            for(let i=0; i<this.dataList.length; i++){
                let item = this.dataList[i]
                let a = {
                    no: i+1,
                    startPortName: item.startPortName,
                    throughPortName: item.throughPortName,
                    endPortName: item.endPortName,
                    numOfSailor: item.numOfSailor,
                    numOfVipAdult: item.numOfVipAdult,
                    numOfVipChild: item.numOfVipChild,
                    numOfVipBaby: item.numOfVipBaby,
                    numOfNormalAdult: item.numOfNormalAdult,
                    numOfNormalChild: item.numOfNormalChild,
                    numOfNormalBaby: item.numOfNormalBaby,
                    numOfPassenger: item.numOfPassenger,
                    sailTime: item.sailTime,
                    voyageNumber: item.voyageNumber,
                    createdTime: item.createdTime,
                    createdName: item.createdName,
                    remark: item.remark
                }
                arr.push(a)
            }
            return arr
        },

        exportExcel(){
            const Exceljs = require('exceljs'); // 引入exceljs
	        const workbook = new Exceljs.Workbook(); // 创建工作簿
            const worksheet = workbook.addWorksheet('航班信息')

            const columns = [
                { header: '序号', key: 'no', width: 8},
                { header: '始发港', key: 'startPortName', width: 15},
                { header: '途经港', key: 'throughPortName', width: 12},
                { header: '到达港', key: 'endPortName', width: 15},
                { header: '船员人数', key: 'numOfSailor', width: 10},
                { header: '头等舱成人数', key: 'numOfVipAdult', width: 8},
                { header: '头等舱儿童数', key: 'numOfVipChild', width: 8},
                { header: '头等舱婴儿数', key: 'numOfVipBaby', width: 8},
                { header: '普通舱成人数', key: 'numOfNormalAdult', width: 8},
                { header: '普通舱儿童数', key: 'numOfNormalChild', width: 8},
                { header: '普通舱婴儿数', key: 'numOfNormalBaby', width: 8},

                { header: '旅客总数', key: 'numOfPassenger', width: 10},
                { header: '开航时间', key: 'sailTime', width: 20},
                { header: '航班号', key: 'voyageNumber', width: 15},
                { header: '入库时间', key: 'createdTime', width: 20},
                { header: '录入人', key: 'createdName', width: 15},
                { header: '备注', key: 'remark', width: 20},
            ]
            worksheet.columns = columns

            worksheet.addRows(this.convertDataList())

            // 定义边框样式
            const borderStyle = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            worksheet.eachRow((row, rowIndex) => {
                if(rowIndex===1){
                    row.eachCell((cell, colIndex) => {
                        cell.font = { bold: true };
                        cell.alignment = {
                            vertical: 'middle', // 垂直居中
                            horizontal: 'center', // 水平居中
                            wrapText: true // 自动换行
                        };
                        cell.border = borderStyle
                        cell.fill = {
                            type: 'pattern',
                            pattern: 'solid',
                            fgColor: { argb: '58c2de' } // 设置背景色
                        };
                    });
                }else{
                    // 循环每个单元格		
                    row.eachCell((cell, colIndex) => {
                        cell.alignment = {
                            vertical: 'middle', // 垂直居中
                            horizontal: 'center', // 水平居中
                            wrapText: true // 自动换行
                        };
                        cell.border = borderStyle
                    });
                }
            });

            // 下载工作簿
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), '航班信息-'+this.currShip.name+'.xlsx');
            });
        },

        // 设置显示、隐藏
        setShow(show){
            this.show = show
            this.modalVisibleStat = show
            this.shipList = this.app.shipList
            if(this.shipList.length>0){
                this.currShipId = this.shipList[0].id
                this.currShip = this.shipList[0]
            }
            // 重置
            this.dataList = []
            // 查询港口列表
            this.queryPortList()
        },

        // 查询港口列表
        queryPortList(){
            this.sswgPost('/port/fetchPortList', {}).then(res => {
                if(res){
                    this.portFrom.list = res.data
                    this.portTo.list = res.data
                    this.portThrough.list = res.data
                }
            })
        },

        // 查询数据列表
        queryDataList(){
            console.log(this.value2)
            if(this.value2==null){
                this.$message.warning('请选择【入库时间范围】')
                return 
            }
            let p = {
                shipInfoId: this.currShipId,
                type: this.radio,
                beginDate: this.beginDate,
            }
            if(this.radio==2){
                p.beginDate = this.parseTime(this.value2[0])
                p.endDate = this.parseTime(this.value2[1])
            }
            this.sswgGet('/voyage/voyageRecord/list', p, true, 'json').then(res => {
                if(res){
                    console.log(res)
                    this.dataList = res.data
                }
            })
        },

        validateShipPassword(){
            this.currOperate = 'validate'
            this.modalTitle = '校验船舶静态密码'
            this.modalVisible = true
        },

        add(){
            if(!this.currShipId){
                this.$message.warning('请选择船名')
                return
            }
            this.resetDg()
            // 根据id找船名
            // let ship = this.shipList.find(item => item.id === this.currShipId)
            this.currOperate = 'add'
            this.modalTitle = '添加航班  【'+this.currShip.name+'】'
            this.modalVisible = true
            this.dg.p.shipInfoId = this.currShipId
        },

        edit(item){
            this.resetDg()
            this.currOperate = 'edit'
            this.modalTitle = '编辑航班'
            this.modalVisible = true
            this.dg.currVoyageId = item.id
            this.portFrom.value = parseInt(item.startPortId)
            this.portTo.value = parseInt(item.endPortId)
            // 如果有途经港
            if(item.throughPortId && item.throughPortId!=0){
                this.portThrough.value = parseInt(item.throughPortId)
            }
            this.dg.p.numOfSailor = item.numOfSailor
            this.dg.p.numOfVipAdult = item.numOfVipAdult
            this.dg.p.numOfVipChild = item.numOfVipChild
            this.dg.p.numOfVipBaby = item.numOfVipBaby
            this.dg.p.numOfNormalAdult = item.numOfNormalAdult
            this.dg.p.numOfNormalChild = item.numOfNormalChild
            this.dg.p.numOfNormalBaby = item.numOfNormalBaby
            this.dg.p.createdName = item.createdName
            this.dg.p.voyageNumber = item.voyageNumber
            this.dg.p.sailTime = item.sailTime
            this.dg.p.remark = item.remark
        },
        
        del(id){
            this.dg.currVoyageId = id
            this.currOperate = 'del'
            this.modalTitle = '提示'
            this.modalVisible = true
            this.modalContent = '确认删除此航班数据吗？'
        },

        // 确认modal
        sureModal(){
            if(this.currOperate=='add' || this.currOperate=='edit'){
                if(!this.portFrom.value){
                    this.$message.warning('请选择【始发港】')
                }else if(!this.portTo.value){
                    this.$message.warning('请选择【到达港】')
                }
                else if(!this.dg.p.numOfSailor){
                    this.$message.warning('请填写【船员人数】')
                }else if(!this.dg.p.numOfVipAdult){
                    this.$message.warning('请填写【头等舱成人数】')
                }else if(!this.dg.p.numOfVipChild){
                    this.$message.warning('请填写【头等舱儿童数】')
                }else if(!this.dg.p.numOfVipBaby){
                    this.$message.warning('请填写【头等舱婴儿数】')
                }
                else if(!this.dg.p.createdName){
                    this.$message.warning('请填写【录入人】')
                }else if(!this.dg.p.numOfNormalAdult){
                    this.$message.warning('请填写【普通舱成人数】')
                }else if(!this.dg.p.numOfNormalChild){
                    this.$message.warning('请填写【普通舱儿童数】')
                }else if(!this.dg.p.numOfNormalBaby){
                    this.$message.warning('请填写【普通舱婴儿数】')
                }
                else if(!this.dg.p.voyageNumber){
                    this.$message.warning('请填写【航班号】')
                }else if(!this.dg.p.sailTime){
                    this.$message.warning('请选择【开航时间】')
                }else {
                    let startPort = this.portFrom.list.find(item => item.id === this.portFrom.value)
                    let endPort = this.portTo.list.find(item => item.id === this.portTo.value)
                    this.dg.p.startPortId = this.portFrom.value
                    this.dg.p.startPortName = startPort.name
                    this.dg.p.endPortId = this.portTo.value
                    this.dg.p.endPortName = endPort.name
                    // 如果选择了途经港
                    if(this.portThrough.value){
                        let throughPort = this.portThrough.list.find(item => item.id === this.portThrough.value)
                        this.dg.p.throughPortId = this.portThrough.value
                        this.dg.p.throughPortName = throughPort.name
                    }
                    if(this.currOperate=='add'){
                        this.sswgPost('/voyage/voyageRecord', this.dg.p, true, 'json').then(res => {
                            if(res && res.code==200){
                                this.$message.success('添加成功')
                                this.closeModal()
                                this.queryStat()
                            }
                        })
                    }else if(this.currOperate=='edit'){
                        this.dg.p.id = this.dg.currVoyageId
                        this.sswgPost('/voyage/voyageRecord/edit', this.dg.p, true, 'json').then(res => {
                            if(res && res.code==200){
                                this.$message.success('编辑成功')
                                this.closeModal()
                                this.queryStat()
                            }
                        })
                    }
                    
                }
            }else if(this.currOperate=='del'){
                this.sswgDel('/voyage/voyageRecord/'+this.dg.currVoyageId, {}, true, 'json').then(res => {
                    if(res && res.code==200){
                        this.$message.success('删除成功')
                        this.closeModal()
                        this.queryStat()
                    }
                })
            }else if(this.currOperate=='validate'){
                if(!this.shipPassword){
                    this.$message.warning('请输入密码')
                    return
                }
                let param = {
                    agentId: localStorage.getItem('login_agent_id'),
                    password: this.shipPassword
                }
                this.sswgPost('/outlets/validateShipPassword', param, true).then(res => {
                    if(res){
                        if(res.code==200){
                            this.closeModal()
                            this.hasValidate = true
                        }else{
                            this.$message.warning(res.message)
                        }
                    }
                })
            }
        },

        closeModal(){
            this.modalVisible = false
        }

        
    }

}

</script>

<style lang="scss" scoped>
    .content {
        .v1 {
            display: flex;
            // background-color: aliceblue;
            align-items: center;
            justify-content: space-between;
            padding: 10px 10px 12px 10px;
        }
        .v2 {
            padding: 4px 10px;
            border-top: 1px dashed rgb(148, 147, 147);
            border-bottom: 1px dashed rgb(148, 147, 147);
        }
        // .v3 {
        //     padding: 4px 10px;
        //     span {
        //         margin-right: 15px;
        //         font-size: 12px;
        //     }
        // }
        .v4 {
            height: 440px;
            background-color: #FFFFFF;
            margin: 0 2px 2px 2px;
            overflow-y: auto;
        }

        .c1 {
            padding: 10px;
            // width: 540px;
            width: 590px;
            span {
                display: inline-block;
                width: 100px;
                // margin-bottom: 20px;
                line-height: 40px;
                font-size: 14px;
            }
            span::before {
                content: "*";
                color: red;
                margin-right: 2px;
            }
        }
    }

    .btn {
        font-size: 14px;
        height: 24px;
        line-height: 24px;
        padding: 4px 10px;
        background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
        margin-left: 20px;
        color: #000;
        border-radius: 4px;
        cursor: pointer;
        user-select: none;
    }
    .btn2 {
        padding: 2px 20px;
        margin-left: 0px;
        margin-right: 20px;
    }

    .btn3 {
        color: #1762c3;
        border: 1px solid #0078d7;
        border-radius: 2px;
        padding: 1px 4px;
        background-color: #f0f0f0;
        cursor: pointer;
        font-size: 12px;
        margin-left: 4px;
        user-select: none;
    }

    .no-star::before {
        content: '*' !important;
        color: rgba(255, 0, 0, 0) !important;
    }
    

</style>

<style scoped>
    .content >>> .el-form-item {
        margin-bottom: 0px;
    }
    .content >>> .el-form-item__label {
        color: #FFFFFF;
    }
    .content >>> input.el-input__inner {
        font-size: 15px;
        font-weight: bold;
        height: 26px;
        line-height: 26px;
        background: none;
        color: #FFFFFF;
    }
    .content >>> .el-input__icon {
        line-height: normal;
    }

    .content >>> .el-input__inner {
        background: none;
        height: 26px;
        line-height: 26px;
    }

    .content >>> .el-date-editor .el-range-input {
        height: 26px;
        background: none;
        font-size: 15px;
        font-weight: bold;
        color: #FFF;
    }

    .content >>> .el-date-editor .el-range-separator {
        height: 26px;
        line-height: 26px;
    }

    .v1 >>> .el-radio {
        color: #FFF;
        margin-right: 10px;
    }
    .v1 >>> .el-radio__label {
        padding-left: 4px;
    }
    .v1 >>> .el-radio__input.is-checked+.el-radio__label {
        color: #85e984;
    }
    

    .custom-table >>> .cell {
        font-size: 12px;
        color: #000000;
        padding: 5px 0px;
    }
    .custom-table >>> th {
        background: linear-gradient(to bottom, #9cd4e3, #33b8db);
    }
    /** 表头样式 */
    .custom-table >>> th.el-table__cell>.cell {
        line-height: 22px;
        padding: 0 0;
        color: #000;
        font-weight: 400;
        border: 1px solid #FFF;
    }
    .custom-table >>> .el-table__cell {
        padding: 0 0;
    }
    /* 修改表格行的鼠标悬停颜色 */
    .custom-table >>> .el-table__body tr:hover>td.el-table__cell {
        background-color: #86e9f9 !important;
    }
    /* 修改表格选中行颜色 */
    .custom-table >>> .el-table__body tr.current-row>td.el-table__cell {
        background-color: #3ebcf6 !important;
    }

</style>