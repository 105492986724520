import {MercatorTransform, LatLonUtility} from '/public/hd/chartbox';

export function enPointToLLPoint(x, y) {
	const transform = new MercatorTransform();
	return transform.enPointToLLPoint(x, y);
}

export function llPointToENPoint(lon, lat) {
	const transform = new MercatorTransform();
	return transform.llPointToENPoint(lon, lat);
}

export function enPointsToLLPoints(value) {
	const transform = new MercatorTransform();
	return transform.enPointsToLLPoints(value);
}

export function llPointsToENPoints(value) {
	const transform = new MercatorTransform();
	return transform.llPointsToENPoints(value);
}


export function enRectToLLRect(x, y, w, h) {
	const transform = new MercatorTransform();
	return transform.enRectToLLRect(x, y, w, h);
}

export function llRectToENRect(x, y, w, h) {
	const transform = new MercatorTransform();
	return transform.llRectToENRect(x, y, w, h);
}

export function latToDegreeMinString(v, dec) {
	return LatLonUtility.latToDegreeMinString(v, dec);
}

export function lonToDegreeMinString(v, dec) {
	return LatLonUtility.lonToDegreeMinString(v, dec);
}


export function stringToLat(value) {
	return LatLonUtility.stringToLat(value);
}

export function stringToLon(value) {
	return LatLonUtility.stringToLon(value);
}















//度分秒转换为度
export function degMinSecToDegree(du, fen, miao) {
	var mFen = 0;
	if (miao != null && miao != '') {
		mFen = Number(miao / 60);
	}
	var fDu = 0;
	if (fen != null && fen != '') {
		fDu = (Number(fen) + mFen) / 60;
	} else {
		fDu = mFen;
	}
	var lDu = 0;
	if (du != null && du != '') {
		lDu = +(Number(du) + fDu).toFixed(6);
	} else {
		lDu = +fDu.toFixed(6);
	}
	return lDu;
}












//  // 经度
//  /^((\d|[1-9]\d|1[0-7]\d)[°](\d|[0-5]\d)[′](\d|[0-5]\d)(\.\d{1,2})?[″]?$)|(180[°]0[′]0[″]?$)/.test('180°0′0″')
//  // 纬度
//  /^((\d|[1-8]\d)[°](\d|[0-5]\d)[′](\d|[0-5]\d)(\.\d{1,2})?[″]?$)|(90[°]0[′]0[″]?$)/.test('90°0′0″')
//      // 取值范围：经度0°0′0″~180°0′0″  纬度0°0′0″~90°0′0″
//      (1.度分秒都要有，且度分为整数
//       2.度数开头不能为0（如02°);
//       3.秒的数字是小数，且小数点最多2位小数
//       4.分秒不能超过60(只能59.99))
