<template>
    <div class="content">
        <div class="nav">
            <ul>
                <li class="navli" v-for="(item, index) in menuList" @mouseenter="mouseenterMenu(1, item.perm)">
                    <span>{{ item.title }}</span>
                    <ul v-if="item.perm==currMenuLv1Perm" @mouseleave="mouseleaveMenu()">
                        <li class="arrow" v-for="(item2, index2) in item.children" @mouseenter="mouseenterMenu(2, item2.perm)">
                            <span>{{ item2.title }}</span>
                            <ul v-if="item2.perm==currMenuLv2Perm">
                                <template v-for="(item3, index3) in item2.children">
                                <li>
                                    <span>{{ item3.title }}</span>
                                </li>
                                </template>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

            
            <div class="login">
                <span class="login-name" v-if="hasLogin">{{ loginName }}</span>
                <span class="btn login-out" v-if="hasLogin" @click="logout">注销</span>
                <span class="btn" v-else @click="login">登录</span>
            </div>

        </div>

        <Login></Login>
        
    </div>
</template>

<script>
import Login from '../login/Login.vue';
export default {
    components: {
        Login,
    },
    data() {
        return {
            // loginName: localStorage.getItem('login_user_name'),

            // 当前一、二级菜单标识
            currMenuLv1Perm: '',
            currMenuLv2Perm: '',
            menuList: [
                {
                    title: '系统设置',
                    perm: 'sys_setting',
                    children: [
                        {
                            title: '经纬度格式',
                            perm: 'lat_format',
                            children: [
                                {
                                    title: '度',
                                    perm: '',
                                },
                                {
                                    title: '度分',
                                    perm: '',
                                },
                                {
                                    title: '度分秒',
                                    perm: '',
                                }
                            ]
                        },
                        // {
                        //     title: '修改登陆密码',
                        //     perm: 'update_pwd',
                        //     children: [
                        //         {
                        //             title: '修改',
                        //             perm: '',
                        //         },
                        //         {
                        //             title: '不修改',
                        //             perm: '',
                        //         },
                        //     ]
                        // }
                    ]
                },
                // {
                //     title: '船舶显示设置',
                //     perm: 'ship_setting',
                //     children: [
                //         {
                //             title: '船名',
                //             perm: 'ship_name',
                //             children: [
                //                 {
                //                     title: '显示',
                //                     perm: '',
                //                 },
                //                 {
                //                     title: '不显示',
                //                     perm: '',
                //                 },
                //             ]
                //         }
                //     ]
                // }
            ],

        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        },
        loginName(){
            return this.$store.state.loginUserName
        }
    },
    mounted(){

    },
    methods: {
        // 鼠标移动到li触发的事件
        mouseenterMenu(lv, perm){
            // console.log(perm)
            if(lv==1){
                this.currMenuLv1Perm = perm
                this.currMenuLv2Perm = ''
            }else if(lv==2){
                this.currMenuLv2Perm = perm
            }
            
        },

        // 鼠标离开第三级菜单，隐藏二三级菜单
        mouseleaveMenu(){
            this.currMenuLv1Perm = '-'
        },

        login(){
            this.logout()
        },

        // 注销
        logout(){
            this.$store.commit('logout')
        }

    }

}

</script>

<style lang="scss" scoped>
    .content {
        display: flex;
    }

    .nav {width: 100%; height: 28px; background-color: #1686d8; position: relative; z-index: 100;}
    .nav>ul {width: max-content;}
    .nav>ul>li {float: left; padding: 0 5px; height: 28px; line-height: 28px; position: relative; cursor: pointer;}
    .nav>ul>li>span {font-size: 12px; color: #fff;}
    .nav>ul>li:hover {background: #86e9f9;}
    .nav>ul>li:hover>span {color: #000;}

    .navli>ul {border: 3px solid #034da5;background:url(../../assets/img/nav/bg.png) left top / 160px 100% no-repeat #034da5;position: absolute;left: 0;top: 28px;border-radius: 5px; width: max-content;}
    .navli>ul li{font-size: 12px; color: #fff;}
    .navli li {display: flex; position: relative; padding-left:30px; padding-right: 60px;}
    .navli li:hover{background:linear-gradient(to right,#31b6f7,#034da5);}

    .navli .arrow {background:url(../../assets/img/nav/arrow.png) 96% center / auto 8px no-repeat;}
    .navli li.arrow:hover{background: url(../../assets/img/nav/arrow.png) 96% center / auto 8px no-repeat, linear-gradient(to right,#31b6f7,#034da5);}
    .navli ul li ul{border: 3px solid #034da5;background:url(../../assets/img/nav/bg.png) left top / 160px 100%  no-repeat #034da5;position: absolute;left:100%;top: 0;border-radius: 5px;width: max-content;} // display: none;
    .navli ul li ul li{padding-right: 0;}
    .navli ul li ul span{height: 28px;display: block;padding-right: 60px;width: 100%;}

    .login {
        color: #FFF;
        float: right;
        margin-right: 20px;
        margin-top: 4px;
        .btn {
            padding: 3px 14px;
            width: max-content;
            background-color: #1ead15;
            font-size: 12px;
            border-radius: 3px;
            cursor: pointer;
            box-shadow: inset -1px -1px 1px #3b3b3b;
            user-select: none;
        }
        .btn:active {
            box-shadow: inset -1px -1px 1px #020202;
        }
        .login-name {
            padding: 0 10px;
        }
        .login-out {
            background-color: rgb(206, 26, 26);
        }
    }
</style>