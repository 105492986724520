export let baseUrl = ''
export let baseApi = ''
export let webSocketUrl = ''
export let baseResourceUrl = ''

export let iccWsURL = ''            // ICC播放器推流地址

export let currEnv = 'prod'

if(currEnv=="prod") {
    baseUrl = "https://vms2.sswgsz.com"
    baseApi = baseUrl + "/webapi/"
    webSocketUrl = "wss://vms2.sswgsz.com"
    baseResourceUrl = baseApi
    iccWsURL = 'wss://vms2.sswgsz.com/realmonitor-websocket?serverIp=218.17.42.228'
}else if(currEnv=="test") {
    baseUrl = "https://test.vms.sswgsz.com:3443"
    baseApi = baseUrl + "/webapi/"
    webSocketUrl = "wss://test.vms.sswgsz.com"
    baseResourceUrl = baseApi
    iccWsURL = 'wss://test.vms.sswgsz.com:3443/realmonitor-websocket?serverIp=218.17.42.228'
}else if(currEnv=="dev") {
    baseUrl = "http://172.16.31.209:9977"
    baseApi = baseUrl + "/webapi/"
    webSocketUrl = "ws://172.16.31.209:9050"
    baseResourceUrl = baseUrl + '/'     // 开发环境与正式环境不一样
    iccWsURL = 'wss://test.vms.sswgsz.com:3443/realmonitor-websocket?serverIp=218.17.42.228'
}
