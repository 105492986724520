import store from '@/store'
import { transferIndex, transferIncrease } from '@/utils'

export default {
    inserted(el, binding) {

        let headerEle = el.querySelector(binding.value)

        el.addEventListener('click', (e) => {
            e.stopPropagation();
            transferIncrease()
            if (el.offsetParent) {
                (el.offsetParent).style.zIndex = transferIndex.value
            }
        })

        if (!headerEle) {
            return;
        }

        document.onselectstart = function () {
            return false;
        }

        headerEle.style.cursor = 'move';

        const clientWidth = document.documentElement.clientWidth;
        const clientHeight = document.documentElement.clientHeight;

        headerEle.onmousedown = function (ev) {
            (el.offsetParent).style.zIndex = transferIndex.value

            ev.stopPropagation();
            store.commit('changeModelDispaly', true)

            const disx = ev.pageX - el.offsetLeft;
            const disy = ev.pageY - el.offsetTop;

            // 解决快速频繁拖动滞后问题
            if (ev.preventDefault) {
                ev.preventDefault() // 非IE浏览器
            } else {
                ev.returnValue = false  // IE浏览器
            }

            window.onmousemove = function (e) {
                e.preventDefault();
                e.stopPropagation();
                // 鼠标位置-鼠标相对元素位置=元素位置
                let left = e.clientX - disx;
                let top = e.clientY - disy;

                // 限制拖拽范围不超出可视区
                if (left <= 0) {
                    left = 5;
                } else if (left > (clientWidth) - el.clientWidth) { // document.documentElement.clientWidth屏幕可视区宽度
                    left = (clientWidth) - el.clientWidth - 5;
                }

                if (top <= 0) {
                    top = 5;
                } else if (top > (clientHeight) - el.clientHeight) {
                    top = (clientHeight) - el.clientHeight - 5;
                }


                el.style.left = left + 'px';
                el.style.top = top + 'px';
            }

            window.onmouseup = function () {
                window.onmousemove = null;
                window.onmouseup = null;
                store.commit('changeModelDispaly', false)
                // bodyEle.style.pointerEvents = 'none';

            }
        }
    },
    unbind(el, binding) {

        let headerEle = null
        if (binding.value instanceof Array) {
            headerEle = el.querySelector(binding.value[0])
        } else if (typeof binding.value === 'string') {
            headerEle = el.querySelector(binding.value)
        }

        if (!headerEle) {
            return;
        }
        headerEle.onmousedown = null;
    }
}
