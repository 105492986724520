<template>
    <div class="map-setting" :class="{ hidden: collpase }">
        <BaseTabs @on-click="tabChange">
            <BaseTabPane label="配置" name="name1">
                <ChartSetting></ChartSetting>
            </BaseTabPane>
            <!-- <BaseTabPane label="目标" name="name2">
                <TargetSetting></TargetSetting>
            </BaseTabPane>
            <BaseTabPane label="物标" name="name3">
                <ItemSetting v-if="active3"></ItemSetting>
            </BaseTabPane>
            <BaseTabPane label="气象" name="name4">
                <MeterorlogySetting></MeterorlogySetting>
            </BaseTabPane> -->
        </BaseTabs>
        <div class="collpase" @click="changCollpase">
            <i class="el-icon-arrow-left" v-if="!collpase"></i>
            <i class="el-icon-arrow-right" v-if="collpase"></i>
        </div>
    </div>
</template>

<script>
import ChartSetting from './chartSetting.vue';
// import TargetSetting from './targetSetting.vue';
// import ItemSetting from './itemSetting.vue';
// import MeterorlogySetting from './meterorlogySetting.vue';

export default {
    name: 'MapSetting',
    components: {
        ChartSetting,
        // TargetSetting,
        // ItemSetting,
        // MeterorlogySetting,
    },
    data() {
        return {
            active3: false,
            collpase: true,
        }
    },
    methods: {
        tabChange(v) {
            this.active3 = v.name === 'name3'
        },
        changCollpase() {
            this.collpase = !this.collpase;
        },
    }
}
</script>

<style lang="scss" scoped>
.map-setting {
    width: 340px;
    background-color: #FFFFFF;
    height: calc(100% - 118px);
    position: fixed;
    left: 0;
    top: 88px;
    transition: linear .25s;

    z-index: 199;

    &.hidden {
        transform: translateX(-340px);
    }

    .collpase {
        text-align: center;
        width: 12px;
        height: 80px;
        line-height: 80px;
        color: #666;
        position: absolute;
        right: -12px;
        top: calc(50% - 60px);
        z-index: 1;
        background: #ffffff;
        border-radius: 0 4px 4px 0;
        transition: all .2s;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;

        &:hover {
            color: #fff;
            background-color: #868ac8;
        }
    }
}
</style>