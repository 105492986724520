<template>
    <div class="content" v-if="dataList.length>0">
        <div class="box">
            <div class="box-top">
                <span class="box-title">历史轨迹列表</span>
                <div class="top-btn">
                    <img alt src="../../assets/img/common/close.png"  @click="closeBox()"/>
                </div>
            </div>
            <div class="box-content">
                <ul v-for="(item, i) in dataList" :key="i" @click="" :style="currTrackId==item.id ? 'background-color: #3ebcf6;' : ''">
                    <li class="c1">{{ i+1 }}</li>
                    <li class="c2">{{ item.name }}</li>
                    <li class="c3">
                        <img alt :src="require('@/assets/img/common/'+(item.selected?'open.svg':'hide.svg'))" width="26" @click="clickChecked(item)"/>
                    </li>
                    <li class="c4"><i class="el-icon-close" @click="del(item.trackId)"></i></li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
    inject: ['instanceProvide', 'app'],
    props: {
        dataList: {
            type: Array,
            default: () => { return [] }
        }
    },
    data() {
        return {
            currTrackId: ''
        }
    },
    methods: {
        closeBox(){
            // 先删除所有轨迹
            this.instanceProvide.targetTrackLayer.clearItems()
            this.app.trackDataList = []
            this.app.updateTrackSelected()
        },

        // 选中
        clickChecked(item){
            let b = !item.selected
            this.instanceProvide.targetTrackLayer.setSelected(item.trackId, b)
            item.selected = b
        },

        // 删除轨迹
        del(trackId){
            // 更新列表数据
            let list = this.app.trackDataList
            list = list.filter(item => item.trackId!==trackId)
            this.app.trackDataList = list
            // 移除海图上的轨迹
            this.instanceProvide.targetTrackLayer.removeTrackItem(trackId)
            // 如果数据为空，则触发轨迹选中按钮取消选中
            if(list.length==0){
                this.app.updateTrackSelected()
            }
        }

    }
}
</script>

<style lang="scss" scoped>
    .content {
        position: absolute;
        right: 10px;
        top: 10px;
        .box {
            border: 2px solid #FFFFFF;
            border-radius: 5px;
            background-color: rgba(3, 76, 165, 0.9);
            .box-top {
                height: 32px;
                text-align: center;
                position: relative;
                border-bottom: 1px solid #b2c8da;
                color: #FFF;
                .box-title {
                    line-height: 30px;
                    font-size: 14px;
                    user-select: none;
                }
                .top-btn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    img {
                        cursor: pointer;
                    }
                }
            }
            .box-content {
                background-color: #e5efff;
                border: 1px solid #000000;
                ul {
                    display: flex;
                    text-align: center;
                    line-height: 36px;
                    .c1 {
                        width: 50px;
                    }
                    .c2 {
                        width: 140px;
                    }
                    .c3 {
                        width: 60px;
                    }
                    .c4 {
                        width: 80px;
                    }
                    li {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        i {
                            cursor: pointer;
                            font-size: 22px;
                            
                        }
                        img {
                            cursor: pointer;
                        }
                    }
                }
                // ul:hover {
                //     background-color: #86e9f9;
                //     cursor: pointer;
                // }
            }
        }
    }
</style>