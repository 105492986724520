<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted() {
    // document.body.addEventListener('touchmove', function (event) {
    //   event.preventDefault()
    // }, { passive: false })

    // document.body.addEventListener(
    //   "touchstart",
    //   function (event) {
    //     if (event.touches.length > 1) {
    //       event.preventDefault();
    //     }
    //   },
    //   { passive: false }
    // );
    // // 禁止双击放大
    // let lastTouchEnd = 0;
    // document.body.addEventListener(
    //   "touchend",
    //   function (event) {
    //     var now = Date.now();
    //     if (now - lastTouchEnd <= 300) {
    //       event.preventDefault();
    //     }
    //     lastTouchEnd = now;
    //   },
    //   { passive: false }
    // );
  },

}

</script>

<style lang="scss">
@import '@/styles/common.scss';

body {
  overflow: hidden;
  touch-action: none;
  // touch-action: pan-y;
}

#app {
  color: #2c3e50;
  font-family: PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased // 这个属性可以使页面上的字体抗锯齿,使用后字体看起来会更清晰舒服
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>

<style>
.el-notification {
  background-color: #a9e2ed !important;
  width: auto !important;
  border: 1px solid #3cb6f6 !important;
}



</style>
