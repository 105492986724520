<template>
    <div class="content" v-if="show">
        <div class="v1">
            <div class="modal-content" @click.stop>
                <div class="box-top">
                    <span class="box-title">航点管理</span>
                    <div class="top-btn">
                        <img alt src="../../assets/img/common/close.png" @click="show=false"/>
                    </div>
                </div>
                <div class="box-content">
                    <ul class="table-title" style="cursor: default;">
                        <li class="c1">序号</li>
                        <li class="c2">航点名称</li>
                        <li class="c3">航点经度</li>
                        <li class="c4">航点纬度</li>
                        <li class="c5">排序号</li>
                    </ul>
                    <ul v-for="(item, i) in dataList" :key="i" @click="checkedWayPoint(item)" :style="{backgroundColor: currWayPointId==item.id?'#3ebcf6':''}">
                        <li class="c1">{{ i+1 }}</li>
                        <li class="c2">{{ item.label }}</li>
                        <li class="c3">{{ convertLonLat(1, item.lot) }}</li>
                        <li class="c4">{{ convertLonLat(2, item.lat) }}</li>
                        <li class="c5">100</li>
                    </ul>
                </div>
                <div class="box-footer">
                    <button @click="btnAdd">添加航点</button>
                    <button @click="btnEdit">编辑航点</button>
                    <button @click="btnDel">删除航点</button>
                    <button @click="btnResetOrderno">重置排序号</button>
                </div>
            </div>
        </div>
        <div class="v2" v-if="showDg">
            <el-form :model="dg.p" label-width="40px" label-position="right">
                <el-form-item label="名称">
                    <el-input v-model="dg.p.label" clearable maxlength="40" placeholder="请输入航点名称"></el-input>
                </el-form-item>
                <el-form-item label="经度">
                    <el-input v-model="dg.p.lot" clearable maxlength="40"></el-input>
                </el-form-item>
                <el-form-item label="纬度">
                    <el-input v-model="dg.p.lat" clearable maxlength="40"></el-input>
                </el-form-item>
                <el-form-item label="排序" v-if="currOprate=='edit'">
                    <el-input v-model="dg.p.orderno" type="number"></el-input>
                </el-form-item>
                <el-form-item>
                    
                </el-form-item>
            </el-form>
            <div class="box-footer" style="padding-left: 20px;">
                <button @click="sure">确认</button>
                <button @click="close">取消</button>
            </div>
        </div>

        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" :openFooter="openFooter" @sure="sureModal">
            <div class="box-content">
                确认要删除此航点数据【{{currWayPoint.label}}】吗?
            </div>
        </SswgModal>
    </div>
</template>

<script>
import { lonToDegreeMinString, latToDegreeMinString } from '@/utils'
export default {
    data() {
        return {
            show: false,
            dataList: [],

            showDg: false,
            dg: {
                p: {
                    label: '',
                    lot: undefined,
                    lat: undefined,
                    orderno: 0
                }
            },
            currWayPointId: 0,
            currWayPoint: {},

            currOprate: '', // 当前操作 add edit del
            
            modalVisible: false,
            modalTitle: '提示',
            openFooter: true
        }
    },

    methods: {

        convertLonLat(n, v){
            if(n==1){
                return lonToDegreeMinString(v, 4)
            }else{
                return latToDegreeMinString(v, 4)
            }
        },

        queryDataList(){
            this.sswgPost('/waypoint/fetchWaypointList', {agentId: localStorage.getItem('login_agent_id')}).then(res => {
                console.log(res)
                this.dataList = res.data
            })
        },

        // 确认添加或修改
        sure(){
            if(!this.dg.p.label){
                this.$message.warning('请输入航点名称')
            }else if(!this.dg.p.lot || !this.dg.p.lat){
                this.$message.warning('请输入经纬度')
            }else if(this.currOprate=='add'){
                this.dg.p.agentId = localStorage.getItem('login_agent_id')
                this.sswgPost('/waypoint/addWaypoint', this.dg.p, true, 'json').then(res => {
                    this.$message.success('添加成功')
                    this.queryDataList()
                    this.close()
                })
            }else if(this.currOprate=='edit'){
                let param = {
                    id: this.currWayPointId,
                    label: this.dg.p.label,
                    lot: this.dg.p.lot,
                    lat: this.dg.p.lat
                }
                this.sswgPost('/waypoint/updateWaypoint', param, true, 'json').then(res => {
                    this.$message.success('编辑成功')
                    this.queryDataList()
                    this.close()
                })
            }
        },

        

        close(){
            this.showDg = false
            this.dg.p.label = ''
            this.dg.p.lot = undefined
            this.dg.p.lat = undefined
            this.dg.p.orderno = 0
            this.currOprate = ''
            this.currWayPointId = 0
        },

        checkedWayPoint(item){
            console.log(item)
            this.currWayPointId = item.id
            this.currWayPoint = item
        },

        btnAdd(){
            this.close()
            this.currWayPointId = 0
            this.currOprate = 'add'
            this.showDg = true
        },

        btnEdit(){
            if(this.currWayPointId == 0){
                this.$message.warning('请先选中航点数据')
                return
            }
            this.currOprate = 'edit'
            this.dg.p.label = this.currWayPoint.label
            this.dg.p.lot = this.currWayPoint.lot
            this.dg.p.lat = this.currWayPoint.lat
            this.dg.p.orderno = this.currWayPoint.orderno
            this.showDg = true
        },

        btnDel(){
            if(this.currWayPointId == 0){
                this.$message.warning('请先选中航点数据')
                return
            }
            this.currOprate = 'del'
            this.modalVisible = true
            this.showDg = false
        },

        sureModal(){
            if(this.currOprate == 'del'){
                let param = {
                    agentId: localStorage.getItem('login_agent_id'),
                    id: this.currWayPointId
                }
                this.sswgPost('/waypoint/deleteWaypoint', param, true, 'json').then(res => {
                    this.$message.success('删除成功')
                    this.queryDataList()
                    this.close()
                    this.modalVisible = false
                })
            }
        },

        btnResetOrderno(){
            this.$message.warning('功能待完善')
        },

        // 设置经纬度
        setLonLat(lon, lat){
            this.dg.p.lot = lon.toFixed(7)
            this.dg.p.lat = lat.toFixed(7)
        },

        setShow(){
            let b = this.show
            if(!b){
                this.queryDataList()
            }
            this.show = !b
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        // z-index: 1000000;
        // position: relative;
        .v1 {
            position: absolute;
            z-index: 210;
            left: 6px;
            top: 6px;
            .modal-content {
                z-index: 220;
                background: linear-gradient(to right,#06397b,#015cc3);
                border: 1px solid #b2c8da;
                border-radius: 5px;
                min-width: 280px;   // 根据当前modal变动
                color: #ffffff;
                .box-top {
                    height: 32px;
                    text-align: center;
                    position: relative;
                    border-bottom: 1px solid #b2c8da;
                    .box-title {
                        line-height: 30px;
                        font-size: 14px;
                        user-select: none;
                    }
                    .top-btn {
                        position: absolute;
                        right: 5px;
                        top: 5px;
                        user-select: none;
                        img {
                            cursor: pointer;
                        }
                    }
                }
                .box-content {
                    // padding: 5px;
                    margin: 5px;
                    background-color: #FFFFFF;
                    max-height: 500px;
                    overflow-y: auto;
                    ul {
                        display: flex;
                        text-align: center;
                        line-height: 22px;
                        font-size: 12px;
                        color: #000;
                        .c1 {
                            width: 50px;
                        }
                        .c2 {
                            width: 120px;
                        }
                        .c3 {
                            width: 90px;
                        }
                        .c4 {
                            width: 90px;
                        }
                        .c5 {
                            width: 60px;
                        }

                    }
                    ul:hover {
                        background-color: #86e9f9;
                        cursor: pointer;
                    }
                    .table-title {
                        // background: url(../../assets/img/common/table_bg.png) left top / 100% 100% no-repeat #1832a8;
                        color: #FFF;
                        font-size: 12px;
                        line-height: 20px;
                        user-select: none;
                        background-color: #0393ed;
                    }
                    .table-title:hover{
                        background-color: #0393ed;
                    }
                }
                // .box-footer {
                //     display: flex;
                //     justify-content: space-around;
                //     padding: 10px 0;
                //     button {
                //         width: 30%;
                //         height: 30px;
                //         line-height: 30px;
                //         border: 1px solid #000;
                //         border-radius: 5px;
                //         background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
                //         cursor: pointer;
                //     }
                // }
            }
        }

        .box-footer {
            display: flex;
            justify-content: space-around;
            padding: 10px 0;
            button {
                width: 22%;
                height: 24px;
                line-height: 24px;
                border: 1px solid #000;
                border-radius: 5px;
                background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
                cursor: pointer;
            }
        }

        .v2 {
            width: 260px;
            // height: 100px;
            background-color: rgba(3, 76, 165, 0.81);
            border-radius: 5px;
            // border: 2px solid #FFF;
            border: 1px solid #b2c8da;
            position: absolute;
            top: 6px;
            left: 450px;
            padding: 10px 10px 0 10px;

            

        }
    }

    /* 修改滚动条的宽度和颜色 */
    // ::-webkit-scrollbar {
    //     width: 10px;
    //     background-color: #1c9bf5;
    // }
</style>

<style scoped>
    .v2 >>> .el-form-item {
        margin-bottom: 0px;
    }
    .v2 >>> .el-form-item__label {
        color: #FFFFFF;
    }
    .v2 >>> input.el-input__inner {
        font-size: 15px;
        font-weight: bold;
        height: 26px;
        line-height: 26px;
        background: none;
        color: #FFFFFF;
    }


    /* 自定义横向滚动条样式 */
    .content::-webkit-scrollbar {
        width: 10px;
    }
    .content::-webkit-scrollbar-thumb {
        background-color: #85e984; /* 设置滚动条颜色 */
        border-radius: 2px; /* 设置滚动条圆角 */
    }
</style>