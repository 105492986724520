<template>
    <div class="base-modal" v-if="value" :style="{ 'z-index': zIndex }">
        <div ref="modalInner" class="base-modal-inner" v-drag="drag"
            :style="{ width: containerWidth, height: height, ...positionStyle }">
            <slot name="header" v-if="showHeader">
                <div class="base-modal-header">
                    <span>{{ title }}</span>
                    <i class="close" @click="closeModal">✕</i>
                </div>
            </slot>
            <slot>
                <div class="base-modal-content">
                    内容
                </div>
            </slot>
            <div class="base-modal-footer" v-if="showFooter">
                <slot name="footer">
                    <el-buttom type="info">取消</el-buttom>
                    <el-button>确定</el-button>
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import { transferIndex, transferIncrease } from '@/utils'

export default {
    name: 'BaseModal',
    emit: ['update:value', 'close'],
    props: {
        drag: {
            type: [Array, String],
            default: '.base-modal-header',
        },
        value: {
            type: Boolean,
            default: true,
        },
        title: {
            type: String,
            default: '标题',
        },
        width: {
            type: [String, Number],
        },
        height: {
            type: [String, Number],
        },
        left: {
            type: [String, Number],
            default: '20%'
        },
        right: {
            type: [String, Number],
        },
        top: {
            type: [String, Number],
            default: '20%'
        },
        showHeader: {
            type: Boolean,
            default: true
        },
        showFooter: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            positionStyle: {
                left: '',
                top: this.top
            },
            zIndex: transferIndex,
            isInit: false,             //控制初始化时是否更新位置
        }
    },
    computed: {
        containerWidth() {
            if (typeof this.width === 'number') {
                return `${this.width}px`
            } else if (typeof this.width === 'string') {
                return this.width
            }
        }
    },
    mounted() {
        this.getZIndex()
        this.getModalWidth()
    },
    beforeUpdate() {
        if (this.isInit) {
            const modalInner = this.$refs.modalInner
            this.positionStyle.left = `${modalInner.offsetLeft}`
            this.positionStyle.top = `${modalInner.offsetTop}`
            this.zIndex = modalInner.offsetParent.style.zIndex
        }
        this.isInit = true
    },
    methods: {
        closeModal() {
            this.$emit('update:value', false)
            this.$emit('close')
        },
        getZIndex() {
            transferIncrease()
        },
        getModalWidth() {
            const modalInner = this.$refs.modalInner
            if (modalInner) {
                const offsetWidth = modalInner.offsetWidth
                const innerWidth = window.innerWidth
                if (this.right) {
                    const right = typeof this.right === 'number' ? this.right : parseFloat(this.right)
                    this.positionStyle.left = `${(window.innerWidth - offsetWidth) - right}px`
                    return
                }
                if (this.left) {
                    this.positionStyle.left = `${this.left}`
                } else {
                    this.positionStyle.left = `${(innerWidth - offsetWidth) / 2}px`
                }
            }
        }
    },
    watch: {

    }

}
</script>

<style scoped lang="scss">
.base-modal {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    overflow: auto;

    // /* 设置滚动条的样式 */
    // &::-webkit-scrollbar {
    //     width: 6px;
    //     // display: none;
    // }

    // /* 滚动槽 */
    // &::-webkit-scrollbar-track {
    //     border-radius: 10px;
    // }

    // /* 滚动条滑块 */
    // &::-webkit-scrollbar-thumb {
    //     border-radius: 10px;
    //     background: rgba(0, 0, 0, 0.1);
    // }

    // &::-webkit-scrollbar-thumb:window-inactive {
    //     background: #2B76A5;
    // }

    &-inner {
        box-sizing: border-box;
        height: inherit;
        position: absolute;
        border-radius: 4px;
        pointer-events: auto;
        background: #fff;
        overflow: hidden;
    }

    &-header {
        height: 32px;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        padding: 0 8px;
        border-radius: 4px 4px 0 0;
        color: #666;
        border-bottom: 1px solid #eee;
        // box-shadow: inset 0 4px 16px 0 #028dd04d, 0 4px 16px 0 #001b3440;
        // background-color: #0e2132ff;
        // background-image: linear-gradient(-45deg,
        //         transparent 15%, rgba(255, 255, 255, .02) 15%,
        //         rgba(255, 255, 255, .02) 30%, transparent 30%,
        //         transparent 45%, rgba(255, 255, 255, .02) 45%,
        //         rgba(255, 255, 255, .02) 60%, transparent 60%,
        //         transparent 75%, rgba(255, 255, 255, .02) 75%,
        //         rgba(255, 255, 255, .02) 90%, transparent);
        // background-image: linear-gradient(-45deg,
        //         transparent 10%, rgba(255, 255, 255, .02) 10%,
        //         rgba(255, 255, 255, .02) 20%, transparent 20%,
        //         transparent 30%, rgba(255, 255, 255, .02) 30%,
        //         rgba(255, 255, 255, .02) 40%, transparent 40%,
        //         transparent 50%, rgba(255, 255, 255, .02) 50%,
        //         rgba(255, 255, 255, .02) 60%, transparent 60%,
        //         transparent 70%, rgba(255, 255, 255, .02) 70%,
        //         rgba(255, 255, 255, .02) 80%, transparent 80%,
        //         transparent 90%, transparent);

        .close {
            cursor: pointer;
            font-style: normal;
            transition: linear .2s;

            &:hover {
                transform: rotate(90deg);
            }
        }
    }

    &-footer {
        height: 60px;
        line-height: 60px;
        text-align: right;
        padding: 0 16px;
    }

}
</style>