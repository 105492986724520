<template>
    <div class="container">
        <BaseCollapse>
            <BaseCollapsePanel title="地图" name="name1">
                <template v-slot:suffix>
                    <el-switch v-model="mapVisible" size="mini" @change="changeActiveValue"></el-switch>
                </template>
                <ul class="map-list">
                    <li class="map-list-item" :class="{ 'is-active': activeValue === 1 }" @click="changeActiveValue(1)">
                        <div class="title">地形图</div>
                    </li>
                    <li class="map-list-item" :class="{ 'is-active': activeValue === 2 }" @click="changeActiveValue(2)">
                        <div class="title">卫星图</div>
                    </li>
                    <li class="map-list-item" :class="{ 'is-active': activeValue === 3 }" @click="changeActiveValue(3)">
                        <div class="title">地图</div>
                    </li>
                </ul>
            </BaseCollapsePanel>
            <BaseCollapsePanel title="海图" name="name2">
                <template v-slot:suffix>
                    <el-switch v-model="chartVisible" size="mini" @change="changeChartDisplay"></el-switch>
                </template>
                <el-row :gutter="20">
                    <el-col :span="8">
                        显示模式
                    </el-col>
                    <el-col :span="16">
                        <el-radio-group size="mini" v-model="s57LayerParams.displayMode">
                            <el-radio-button :label="0" size="small">基础</el-radio-button>
                            <el-radio-button :label="1" size="small">标准</el-radio-button>
                            <el-radio-button :label="2" size="small">全部</el-radio-button>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        点
                    </el-col>
                    <el-col :span="16">
                        <el-radio-group size="mini" v-model="s57LayerParams.symbolStyle">
                            <el-radio-button :label="0" size="small">简单符号</el-radio-button>
                            <el-radio-button :label="1" size="small">纸质海图符号</el-radio-button>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        边界
                    </el-col>
                    <el-col :span="16">
                        <el-radio-group size="mini" v-model="s57LayerParams.boundaryStyle">
                            <el-radio-button :label="0" size="small">简单线</el-radio-button>
                            <el-radio-button :label="1" size="small">符号化</el-radio-button>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        主题模式
                    </el-col>
                    <el-col :span="16">
                        <ul class="theme-list">
                            <li class="theme-list-item" v-for="item in themeList" :key="item.name"
                                :class="{ active: s57LayerParams.colorName == item.name }" @click="themeClick(item)">
                                <div class="theme-item"></div>
                            </li>
                        </ul>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        显示参数
                    </el-col>
                    <el-col :span="16" class="kele">
                        <div>
                            <el-checkbox v-model="s57LayerParams.showText" label="显示文字" size="small" />
                        </div>
                        <div>
                            <el-checkbox v-model="s57LayerParams.nationLanguage" label="使用本地语言" size="small" />
                        </div>
                        <div>
                            <el-checkbox v-model="s57LayerParams.useScaleMin" label="最小比例尺" size="small" />
                        </div>
                        <div>
                            <el-checkbox v-model="s57LayerParams.showsafetydepthcontour" label="显示安全等深线" size="small" />
                        </div>
                        <div>
                            <el-checkbox v-model="s57LayerParams.showChartName" label="显示海图名字" size="small" />
                        </div>
                        <div>
                            <el-checkbox v-model="s57LayerParams.showOutLine" label="显示海图边框" size="small" />
                        </div>
                        <div>
                            <el-checkbox v-model="s57LayerParams.showShallowMark" label="显示浅水标记" size="small" />
                        </div>
                        <div>
                            <el-checkbox v-model="s57LayerParams.showLightRing" label="显示灯环" size="small" />
                        </div>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        水深颜色
                    </el-col>
                    <el-col :span="16">
                        <el-radio-group size="mini" v-model="s57LayerParams.depthColorMode">
                            <el-radio-button :label="0" size="small">两色</el-radio-button>
                            <el-radio-button :label="1" size="small">四色</el-radio-button>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        水深单位
                    </el-col>
                    <el-col :span="16">
                        <el-radio-group size="mini" v-model="s57LayerParams.depthUnit">
                            <el-radio-button :label="0" size="small">米</el-radio-button>
                            <el-radio-button :label="1" size="small">英尺</el-radio-button>
                            <el-radio-button :label="2" size="small">英寻</el-radio-button>
                        </el-radio-group>
                    </el-col>
                </el-row>
                <!-- <el-row :gutter="20">
                    <el-col :span="8">
                        <span>基准水深</span>
                    </el-col>
                    <el-col :span="16">
                        <el-input-number size="mini" v-model="s57LayerParams.baseDeep" />
                        <span style="margin-left: 10px;">{{ unitMapping[s57LayerParams.depthUnit] }}</span>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="8">
                        <span>安全水深</span>
                    </el-col>
                    <el-col :span="16">
                        <el-input-number size="mini" v-model="s57LayerParams.safetyDepth" />
                        <span style="margin-left: 10px;">{{ unitMapping[s57LayerParams.depthUnit] }}</span>
                    </el-col>
                </el-row> -->
                <!-- <el-row :gutter="20">
                    <div class="water-depth-content">
                        <el-col :span="2">
                            <div class="left"></div>
                        </el-col>
                        <el-col :span="22">
                            <el-row :gutter="10">
                                <el-col>
                                    <span>浅水等深线</span>
                                </el-col>
                                <el-col>
                                    <el-input-number size="mini" v-model="s57LayerParams.shallowContour" />
                                </el-col>
                                <el-col>
                                    <span>{{ unitMapping[s57LayerParams.depthUnit] }}</span>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col>
                                    <span>安全等深线</span>
                                </el-col>
                                <el-col>
                                    <el-input-number size="mini" v-model="s57LayerParams.safetyContour" />
                                </el-col>
                                <el-col>
                                    <span>{{ unitMapping[s57LayerParams.depthUnit] }}</span>
                                </el-col>
                            </el-row>
                            <el-row :gutter="10">
                                <el-col>
                                    <span>深水等深线</span>
                                </el-col>
                                <el-col>
                                    <el-input-number size="mini" v-model="s57LayerParams.deepContour" />
                                </el-col>
                                <el-col>
                                    <span>{{ unitMapping[s57LayerParams.depthUnit] }}</span>
                                </el-col>
                            </el-row>
                        </el-col>
                    </div>
                </el-row> -->
            </BaseCollapsePanel>
            <!--
            <BaseCollapsePanel title="控件" name="name3">
                <el-row :gutter="20">
                    <el-col :span="6">
                        旋转
                    </el-col>
                    <el-col :span="18" style="display: flex; align-items: center;">
                        <el-slider :min="-360" :max="360" input-size="mini" v-model="rotation" @change="changeRotation"
                            style="width: 60%; display: inline-block;"></el-slider>
                        <el-input-number v-model="rotation" :min="-360" :max="360" size="small" controls-position="right"
                            @change="changeRotation" style="width: 40%; display: inline-block;margin-left: 8px;" />

                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="6">
                        工具
                    </el-col>
                    <el-col :span="18">
                        <el-button size="mini" @click="addQuickMeasure">距离测量</el-button>
                        <el-button size="mini" @click="addEBLVRM">EBL/VRM</el-button>
                        <el-button size="mini" @click="addSurveyArea">区域测量</el-button>
                        <el-button size="mini" @click="addZoomPart">区域放大</el-button>
                    </el-col>
                </el-row>
            </BaseCollapsePanel>
            -->
        </BaseCollapse>
    </div>
</template>

<script>
import { EBLVRM, QuickMeasure, SurveyArea, SceneZoomPartItem, SceneItemCommon } from '../../../public/hd/chartbox'

export default {
    name: 'ChartSetting',
    inject: ['instanceProvide'],
    data() {
        return {
            themeList: [
                {
                    name: 'roulan',
                    landColor: '#C7BB84',
                    seaColor: '#D4EAEE',
                },
                {
                    name: 'shenqing',
                    landColor: '#46412E',
                    seaColor: '#070707',
                },
                {
                    name: 'qihei',
                    landColor: '#1E1C14',
                    seaColor: '#070707',
                },
            ],
            //电子海图显示参数
            s57LayerParams: {
                language: "ZH",                 //语言
                displayMode: 1,                 //显示模式
                colorName: 'roulan',            //主图颜色
                showText: true,                 //是否显示文字
                showImportantTextOnly: false,   //仅显示重要文字
                nationLanguage: true,           //使用本地语言
                useScaleMin: true,              //使用最小比例尺
                showsafetydepthcontour: true,  //显示安全等深线
                showShallowMark: false,         //显示浅水标记
                showIsolatedObject: false,      //显示孤立危险物标
                showOutLine: false,             //显示海图边框
                showChartName: false,           //显示海图名称
                showLightRing: false,            //显示灯环
                symbolStyle: 0,                 //符号风格
                boundaryStyle: 0,               //边界风格
                depthColorMode: 1,              //水深颜色模式
                depthUnit: 0,                   //水深单位
                safetyDepth: 5,                 //安全水深
                baseDeep: 0,                    //基准水深
                shallowContour: 5,              //浅水等深线
                safetyContour: 10,               //安全等深线
                deepContour: 20,                //深水等深线
            },
            activeValue: 2, //切换地图变量
            unitMapping: ['米', '英尺', '英寻'],
            mapVisible: false, //控制地图显示
            chartVisible: true, //控制海图显示
            rotation: 0, //旋转角度
            latlonGridVisible: false, //经纬网显示
        }
    },
    methods: {
        changeActiveValue(v) {

            if (typeof v === 'boolean' && v) {
                this.instanceProvide.gshhsLayer.setVisible(false)
                this.instanceProvide.aMapLayer.setVisible(this.activeValue == 1)
                this.instanceProvide.googelLayer.setVisible(this.activeValue == 2)
                this.instanceProvide.tiandituLayer.setVisible(this.activeValue == 3)

                this.instanceProvide.aMapMarkerLayer.setVisible(this.activeValue == 1)
                this.instanceProvide.googelMarkerLayer.setVisible(this.activeValue == 2)
                this.instanceProvide.tiandituMarkerLayer.setVisible(this.activeValue == 3)

                this.instanceProvide.s57Layer.hiddenObjectClasses([71, 72, 73, 74], true)

                // 设置初始需要过滤的物标
            } else if (typeof v === 'boolean' && !v) {
                this.instanceProvide.s57Layer.hiddenObjectClasses([71, 72, 73, 74], false)

                this.instanceProvide.gshhsLayer.setVisible(true)

                // 设置初始需要过滤的物标
                this.instanceProvide.aMapLayer.setVisible(false)
                this.instanceProvide.googelLayer.setVisible(false)
                this.instanceProvide.tiandituLayer.setVisible(false)
                this.instanceProvide.googelMarkerLayer.setVisible(false)
                this.instanceProvide.tiandituMarkerLayer.setVisible(false)
                this.instanceProvide.aMapMarkerLayer.setVisible(false)
            } else {
                this.activeValue = v
                if (this.mapVisible) {
                    this.instanceProvide.gshhsLayer.setVisible(false)
                    this.instanceProvide.aMapLayer.setVisible(this.activeValue == 1)
                    this.instanceProvide.googelLayer.setVisible(this.activeValue == 2)
                    this.instanceProvide.tiandituLayer.setVisible(this.activeValue == 3)

                    this.instanceProvide.aMapMarkerLayer.setVisible(this.activeValue == 1)
                    this.instanceProvide.googelMarkerLayer.setVisible(this.activeValue == 2)
                    this.instanceProvide.tiandituMarkerLayer.setVisible(this.activeValue == 3)
                }
            }
        },

        changeChartDisplay(v) {
            this.instanceProvide.s57Layer.setVisible(v)
        },

        themeClick(item) {
            this.s57LayerParams.colorName = item.name

            this.instanceProvide.gshhsLayer.setLandColor(item.landColor)
            this.instanceProvide.gshhsLayer.setSeaColor(item.seaColor)
        },

        changeRotation(v) {
            this.instanceProvide.map.getViewport().setRotation(v);
            this.instanceProvide.map.update();
        },

        changeLatlonGridDisplay(v) {
            this.instanceProvide.latLonGridLayer.setVisible(v);
        },

        addEBLVRM() {
            if (!this.eblVrmLayer) {
                this.eblVrmLayer = new EBLVRM({ map: this.instanceProvide.map });
            }
            this.eblVrmLayer.turnOn();
        },

        addQuickMeasure() {
            if (!this.quickMeasureLayer) {
                this.quickMeasureLayer = new QuickMeasure({ map: this.instanceProvide.map, endType: 1 });
            }
            this.quickMeasureLayer.turnOn();
        },

        addSurveyArea() {
            if (!this.surveyAreaLayer) {
                this.surveyAreaLayer = new SurveyArea({ map: this.instanceProvide.map });
            }
            this.surveyAreaLayer.turnOn();
        },

        addZoomPart() {
            if (!this.zoomPartItem) {
                this.zoomPartItem = new SceneZoomPartItem();
                this.instanceProvide.itemLayer.addItem(this.zoomPartItem);
            }
            this.zoomPartItem.setState(SceneItemCommon.ItemState.adding);
        },
    },
    watch: {
        s57LayerParams: {
            handler(val) {
                this.instanceProvide.s57Layer.setParameter(val)
            },
            deep: true
        }
    }
}

</script>

<style lang="scss" scoped>
.container {
    .el-row {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        font-size: 12px;

        .el-col:first-child {
            color: #303133;
            white-space: nowrap;
        }

        .el-col:last-child {
            color: #303133;
        }
    }

    .el-row:last-child {
        margin-bottom: 0;
    }

    .el-col {
        border-radius: 4px;
    }

    .map-list {
        width: 100%;
        display: flex;
        justify-content: space-between;

        &-item {
            width: 96px;
            height: 72px;
            margin-right: 8px;
            cursor: pointer;
            border-radius: 2px;
            border: 1px solid #eee;
            position: relative;
            overflow: hidden;
            transition: all .2s;

            .title {
                width: 100%;
                position: absolute;
                bottom: 0;
                height: 20px;
                line-height: 20px;
                text-align: center;
                color: #4e5969;
                background: #f2f3f5;
            }

            .mark {
                width: 100%;
                position: absolute;
                top: 0;
                color: #EBFBFF;
                background: #001b3480;
                height: 20px;
                line-height: 20px;
                text-align: center;
            }

            &.is-active {
                border: 1px solid #4a4fad;

                .title {
                    color: #fff;
                    background: #4a4fad;
                }
            }


            &:nth-child(1) {
                background-image: url('../../assets/images/map_1.png');
                background-size: cover;
            }

            &:nth-child(2) {
                background-image: url('../../assets/images/map_2.png');
                background-size: cover;
            }

            &:nth-child(3) {
                background-image: url('../../assets/images/map_3.png');
                background-size: cover;
                margin-right: 0;
            }
        }
    }

    .theme-list {
        display: flex;

        &-item {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            cursor: pointer;
            border-radius: 2px;
            // border: 1px solid transparent;

            &.active {
                border: 2px solid #4a4fad;
            }

            &:nth-child(1) {
                background: linear-gradient(45deg, #FFFBBE 0%, #FFFBBE 50%, #9BDBFF 50%, #9BDBFF 100%);
            }

            &:nth-child(2) {
                background: linear-gradient(45deg, #C7BB84 0%, #C7BB84 50%, #D4EAEE 50%, #D4EAEE 100%);
            }

            &:nth-child(3) {
                background: linear-gradient(45deg, #8F8957 0%, #8F8957 50%, #070707 50%, #070707 100%);
            }
        }
    }

    .el-button+.el-button {
        margin-left: 0;
        margin-top: 10px;
    }

    .el-button {
        margin-right: 10px;
    }
}

.water-depth {
    padding: 0 8px 12px 8px;

    &-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 12px;

        .title {
            margin-left: 14px;
            font-size: 14px;
            color: #1D2129;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                top: 3px;
                left: -12px;
                width: 4px;
                height: 14px;
                background: #4A4FAD;
            }
        }
    }

    .select-bar {
        ul {
            li {
                display: flex;
                align-items: center;
                margin: 8px 0;

                .left {
                    width: 18%;
                    font-size: 12px;
                    transition: ease-in-out 0.2s;
                    color: #4E5969;
                }

                .right {
                    width: 100%;

                    .hh-select {
                        :deep(.el-input__wrapper) {
                            width: 100%;
                            background: none;
                            border: none;
                            box-shadow: none;
                        }

                        :deep(.el-input__inner) {
                            font-size: 12px;
                            color: #4E5969;
                        }

                        :deep(.el-icon) {
                            font-size: 12px;
                            color: #1D2129;
                        }
                    }

                }
            }
        }
    }

    &-content {
        display: flex;
        padding: 8px 12px;

        .left {
            width: 6px;
            height: 128px;
            border-radius: 2px;
            background: linear-gradient(to bottom, #C0F1FE 0%, #C0F1FE 25%, #99E1FD 25%, #99E1FD 50%, #52A5D1 50%, #52A5D1 75%, #2B76A5 75%, #2B76A5 100%);
        }

        .right {
            font-size: 12px;
            color: #4E5969;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            ul {
                width: 100%;
                overflow: hidden;

                li {
                    height: 24px;
                    line-height: 24px;
                    display: flex;
                    justify-content: space-around;
                    margin: 8px 0;
                    white-space: nowrap;
                }
            }
        }
    }
}
</style>

<style scoped>
    /* .el-form-item__label {
        color: #ffffff !important;
    }

    .el-form-item {
        margin-bottom: 15px !important;
    } */

    /* .kele >>> .el-checkbox__label {
        color: #000 !important;
    }

    .kele >>> .el-checkbox__input.is-checked + .el-checkbox__label {
        color: red !important;
    } */

    /* .box-content input.el-input__inner {
        font-size: 16px;
        font-weight: bold; #0078d7
    } */
</style>