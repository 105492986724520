import { render, staticRenderFns } from "./collapse.vue?vue&type=template&id=7c886169&scoped=true"
import script from "./collapse.vue?vue&type=script&lang=ts"
export * from "./collapse.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7c886169",
  null
  
)

export default component.exports