import AES from 'crypto-js/aes'
import enc from 'crypto-js/enc-utf8'
import ECB from 'crypto-js/mode-ecb'
import Pkcs7 from 'crypto-js/pad-pkcs7'

/**
 * 加密
 * @param {*} str 
 * @param {*} k 
 * @returns 
 */
export function encode(str, k) {
    const keyBytes = enc.parse(k)
    try {
        const encryptedBytes = AES.encrypt(str, keyBytes, {
            mode: ECB, // 加解密模式
            padding: Pkcs7, // 填充方式
        })
        return encryptedBytes.toString()
    } catch (err) {
        console.log(err)
        return ''
    }
}
  
/**
 * 解密
 * @param {*} str 
 * @param {*} k 
 * @returns 
 */
export function decode(str, k) {
    const keyBytes = enc.parse(k)
    try {
        const decryptedBytes = AES.decrypt(str, keyBytes, {
            mode: ECB,
            padding: Pkcs7,
        })
        return decryptedBytes.toString(enc)
    } catch (err) {
        console.log(err)
        return ''
    }
}
  