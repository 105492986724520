<template>
    <div class="base-tabs">
        <div ref="navWrap" class="tabs-nav-wrap" :class="[scrollable ? 'tabs-nav-scrollable' : '']">
            <span class="tabs-nav-prev" :class="[scrollable ? '' : 'tabs-nav-scroll-disabled']"
                @click="scrollPrev">&lt;</span>
            <span class="tabs-nav-next" :class="[scrollable ? '' : 'tabs-nav-scroll-disabled']"
                @click="scrollNext">&gt;</span>
            <div ref="navScroll" class="tabs-nav-scroll">
                <div ref="nav" class="tabs-nav" :style="navStyle">
                    <div class="tabs-nav-bar" :style="barStyle"></div>
                    <div class="tabs-tab" :class="{ active: item.name == activeKey }" :style="tabWidth"
                        v-for="(item, index) in navList" :key="index" @click="handleChange(index)">
                        {{ item.label }}</div>
                </div>
            </div>
        </div>
        <div class="pane-tabs-content">
            <slot></slot>
            <!-- <div class="pane-tabs-content-item" v-for="(c, index) in defaults" :key="index"
                :class="{ selected: c.componentOptions.propsData.name === activeKey }">
                <component :is="c" :key="index" />
            </div> -->
        </div>
    </div>
</template>

<script>
import elementResizeDetectorMaker from 'element-resize-detector'
import BaseTabPane from './base-tab-pane.vue'
export default {
    name: 'BaseTabs',
    emit: ['update:value', 'on-click'],
    props: {
        value: {
            type: [String, Number]
        },
    },
    provide() {
        return {
            initTabs: () => this.initTabs()
        }
    },
    data() {
        return {
            navList: [],
            activeKey: this.value,
            barWidth: 0,
            barOffset: 0,
            scrollable: false,
            navStyle: {
                transform: '',
            },
            observer: null,
            defaults: []
        }
    },
    computed: {
        barStyle() {
            return {
                width: `${this.barWidth}px`,
                transform: `translate3d(${this.barOffset}px,0px,0px)`
            }
        },
        tabWidth() {
            return `width: calc(100% / ${this.navList.length});`
        }
    },
    created() {
    },
    beforeUnmount() {
        this.observer.listenTo(this.$refs.navWrap, this.handleResize)
    },
    mounted() {
        this.observer = elementResizeDetectorMaker()
        this.observer.listenTo(this.$refs.navWrap, this.handleResize)
    },
    methods: {
        //初始化更新
        initTabs() {
            this.updateNav()
        },
        //获取tabs下的所有pane实例
        getTabs() {
            // const slots = this.$slots

            // if (slots && slots.default) {
            //     return slots.default.filter((vn) => {
            //         const component = vn.type
            //         return component === BaseTabPane
            //     })
            // } else {
            //     return []
            // }

            // const defaults = this.$children
            // this.defaults = this.$children
            // return defaults.filter((vn) => {
            //     return vn.type === BaseTabPane
            // })

            // 通过遍历子组件，得到所有的pane组件
            return this.$children.filter(item => {
                return item.$options.name === 'BaseTabPane' || item.$options.name === 'basetabpane'
            })
        },
        //获取所有pane组件用户传入的props
        updateNav() {
            this.navList = []
            const paneList = this.getTabs()
            paneList.forEach((pane, index) => {
                this.navList.push({
                    label: pane.label,
                    name: pane.name || index
                })
                //如果不传value,默认选中第一项
                if (index === 0 && !this.activeKey) {
                    this.activeKey = pane.name
                }
            })
            this.updateStatus()
        },
        updateBar() {
            this.$nextTick(() => {
                const index = this.navList.findIndex(nav => nav.name === this.activeKey)
                const elemTabs = this.$refs.navWrap.querySelectorAll('.tabs-tab')
                const elemTab = elemTabs[index]
                this.barWidth = elemTab ? elemTab.offsetWidth : 0
                if (index > 0) {
                    let offset = 0
                    for (let i = 0; i < index; i++) {
                        offset += elemTabs[i].offsetWidth
                    }
                    this.barOffset = offset
                } else {
                    this.barOffset = 0
                }
            })
        },
        updateStatus() {
            const tabs = this.getTabs()
            tabs.forEach((r) => (r.show = r.name === this.activeKey))
            this.$forceUpdate()
        },

        handleChange(index) {
            const nav = this.navList[index]
            this.activeKey = nav.name

            // 更新value
            this.$emit('input', nav.name)

            // this.$emit('update:value', nav.name)
            this.$emit('on-click', nav)
        },
        handleResize() {
            const navWidth = this.$refs.nav.offsetWidth
            const scrollWidth = this.$refs.navScroll.offsetWidth
            if (scrollWidth < navWidth) {
                this.scrollable = true
            } else {
                this.scrollable = false
            }
            this.updateMove()
        },
        updateMove() {
            const navWidth = this.$refs.nav.offsetWidth
            const scrollWidth = this.$refs.navScroll.offsetWidth
            const currentOffset = this.getCurrentScrollOffset()
            if (scrollWidth < navWidth) {
                if (navWidth - currentOffset < scrollWidth) {
                    this.navStyle.transform = `translateX(-${navWidth - scrollWidth}px)`
                }
            } else {
                if (currentOffset > 0) {
                    this.navStyle.transform = `translateX(-${0}px)`
                }
            }
        },
        getCurrentScrollOffset() {
            const reg = /translateX\(-(\d+(\.\d+)*)px\)/
            return this.navStyle.transform ? Number(this.navStyle.transform.match(reg)[1]) : 0
        },
        // setOffset(value) {
        //     this.navStyle.transform = `translateX(-${value}px)`
        // },
        scrollPrev() {
            const containerWidth = this.$refs.navScroll.offsetWidth
            const currentOffset = this.getCurrentScrollOffset()
            if (!currentOffset) return
            let newOffset = 0
            if (currentOffset > containerWidth) {
                newOffset = currentOffset - containerWidth
            }
            this.navStyle.transform = `translateX(-${newOffset}px)`
        },
        scrollNext() {
            const navWidth = this.nav.offsetWidth
            const containerWidth = this.$refs.navScroll.offsetWidth
            const currentOffset = this.getCurrentScrollOffset()
            if (navWidth - currentOffset <= containerWidth) return
            let newOffset = null
            if (navWidth - currentOffset > containerWidth * 2) {
                newOffset = currentOffset + containerWidth
            } else {
                newOffset = navWidth - containerWidth
            }
            this.navStyle.transform = `translateX(-${newOffset}px)`
        }

    },
    watch: {
        value(val) {
            this.activeKey = val
        },
        activeKey() {
            this.updateStatus()
            this.updateBar()
        }
    }
}
</script>

<style lang="scss" scoped>
.base-tabs {
    color: #050505;
    height: 100%;

    .tabs-nav-wrap {
        position: relative;
        border-bottom: 1px solid #eee;
        // margin-bottom: 16px;
        // background: #071a2bff;
        background: inherit;
    }

    .tabs-tab {
        position: relative;
        display: inline-block;
        // margin-right: 16px;
        padding: 8px 16px;
        cursor: pointer;
        color: #4a4fad;
        text-align: center;

        &.active {
            color: #4a4fad;
        }
    }

    .tabs-nav-bar {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: #4a4fad;
        height: 2px;
        transition: transform 300ms ease-in-out;

        &::after {
            content: '';
            position: absolute;
            bottom: 0px;
            left: 0;
            width: 60px;
            height: 30px;
        }
    }

    .tabs-nav-scroll {
        overflow: hidden;
        white-space: nowrap;
    }

    .tabs-nav {
        position: relative;
        transition: transform 0.5s ease-in-out;
    }

    .tabs-nav-prev,
    .tabs-nav-next {
        position: absolute;
        width: 32px;
        line-height: 32px;
        text-align: center;
        cursor: pointer;
    }

    .tabs-nav-prev {
        left: 0;
    }

    .tabs-nav-next {
        right: 0;
    }

    .tabs-nav-scrollable {
        padding: 0 32px;
    }

    .tabs-nav-scroll-disabled {
        display: none;
    }

    .pane-tabs-content {
        padding: 8px;
        position: relative;
        height: calc(100% - 36px);
        overflow-y: auto;

        /* 设置滚动条的样式 */
        &::-webkit-scrollbar {
            width: 6px;
            // display: none;
        }

        /* 滚动槽 */
        &::-webkit-scrollbar-track {
            border-radius: 10px;
        }

        /* 滚动条滑块 */
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background: #E5E6EB;
        }

        &::-webkit-scrollbar-thumb:window-inactive {
            background: #E5E6EB;
        }

        &-item {
            // visibility: hidden;
            display: none;
            // position: absolute;
            // top: 0px;
            // left: 0;
            opacity: 0;
            transition: transform .5s, opacity .7s;
            transform: translateY(20px);

            &.selected {
                // visibility: visible;
                display: block;
                opacity: 1;
                transform: scale(1);
            }
        }

        &::after {
            content: '';
            display: block;
            clear: both;
            overflow: hidden;
        }
    }

}
</style>
